import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

export const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
    if (user) return JSON.parse(user);
    return null;
  };

  // //is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
  };


//COMPETITION ROUTEs
export const getCompetitions = () => api.get(url.GET_COMPETITIONS).then((response) => {
  // console.log(response);
  return response;
})

export const createCompetition = (competition: any) => api.create(url.CREATE_COMPETITION, competition).then((response) => {
  // console.log(response);
  return response;
})


export const getUsersList = () => api.get(url.GET_USERS).then((response: any) => {
  return response;
});


export const saveDeviceToken = (messagingToken : string) => api.create(url.SAVE_MESSAGING_TOKEN, {deviceToken: messagingToken});


export const deleteDeviceToken = (messagingToken: string) => api.create(url.DELETE_MESSAGING_TOKEN, {deviceToken: messagingToken}).catch((error: any) => {
  // console.log(error);
  return Promise.resolve('success');
});




export const getDanceSchools = (danceSchools: any) => api.create(url.GET_DANCE_SCHOOLS, danceSchools);


export const createDanceSchool = (danceSchool: any) => api.create(url.CREATE_DANCE_SCHOOL, danceSchool);


export const addToCompetition = (values: any) => api.put(url.ADD_TO_COMPETITION(values.competition.id), values);

export const removeFromCompetition = (competitionId: string, userId: string) => api.delete(url.REMOVE_FROM_COMPETITION(competitionId, userId));

export const getSongs = () => api.get(url.GET_SONGS);

export const addSongsMultiple = (songs: any) => api.create(url.ADD_SONGS, songs);

export const getPublicSongs = () => api.get(url.GET_PUBLIC_SONGS);

export const updateUser = (userId: string, data: any) => api.put(url.UPDATE_USER(userId),  data );

export const signupUser = (userData: any) => api.create(url.SIGNUP_USER, userData);

export const getUsersByCompetition = (competitionId: string) => api.get(url.GET_USERS_BY_COMPETITION(competitionId));

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dropzone, { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import { DanceType, UploadSongPayload } from "types";
import { addSongsMultiple } from "slices/thunks";
import { error } from "console";
import { FormGroup, Label, Table, Button, Alert, Form, Container, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import classnames from 'classnames';
import SongUploader from "./SongUploader";
import React from "react";
import SongList from "./SongList";
import { bouncy } from "ldrs";


const danceTypeOptions = [
    { value: 'salsa', label: 'Salsa' },
    { value: 'bachata', label: 'Bachata' },
];

const SongManager = () => {

    bouncy.register();

    const selectSongsState = (state: any) => state.Songs;

    const songsSelector = createSelector(
        selectSongsState,
        (songsState: any) => ({
            isLoadingSongs: songsState.loading,
        })
    );

    const { isLoadingSongs } = useSelector(songsSelector);

    const selectCompetitionState = (state: any) => state.Competitions;
    const competitionsSelector = createSelector(
        selectCompetitionState,
        (competitionsState: any) => ({
            isLoadingCompetitions: competitionsState.loading,
        })
    );

    const [activeTab, setActiveTab] = useState<any>('1');
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const { isLoadingCompetitions } = useSelector(competitionsSelector);



    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>                    
                <Row>
                    <Col lg={12}>
                        <Card className="mt-n4 mx-n4">
                            <div className="bg-info-subtle">
                                <CardBody className="pb-0 px-4">
                                    <Row className="mb-3">
                                        <div className="col-md">
                                            <Row className="align-items-center">
                                                <div className="col-md-auto">
                                                    <div>
                                                        <h4 className="fw-bold">Manage Songs</h4>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </Row>
                                    <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                                onClick={() => { toggleTab('1'); }}
                                                href="#"
                                                style={{ cursor: 'pointer' }}>
                                                Song List
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                                onClick={() => { toggleTab('2'); }}
                                                href="#"
                                                style={{ cursor: 'pointer' }}>
                                                Upload Songs
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {isLoadingCompetitions || isLoadingSongs ? (
                    <div className="page-content text-center">
                        <l-bouncy
                            size="75"
                            speed="1.3" 
                            color="orange" 
                        />
                    </div>
                ) : (
                <Row>
                    <Col lg={12}>
                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="1">
                                <SongList competition="null" />
                            </TabPane>
                            <TabPane tabId="2">
                                <SongUploader />
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
                )}
            </Container>
        </div>
     </React.Fragment>
    );
}

export default SongManager;

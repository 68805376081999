// src/Notification.jsx
import React, { useEffect } from 'react';
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import * as firebase from 'firebase/app';
import { requestBrowserPermission } from 'helpers/notifications/notification_helper';
import { toast, ToastContainer } from 'react-toastify';
import { useProfile } from 'Components/Hooks/UserHooks';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const Notification = () => {

    const loginStateSelector = createSelector(
        (state: any) => state.Login,
        (userState) => ({
            token: userState.user
        })
    );

    const { token } = useSelector(loginStateSelector);


    const firebaseApp = firebase.getApp()
    useEffect(() => {
        requestBrowserPermission();
    });

    useEffect(() => {
        
        if(token === undefined) {
            console.log('Nothing in state returning')
            return
        };
        // console.log('State updated')
        const firebaseApp = firebase.getApp();
        onMessage(getMessaging(firebaseApp), (payload) => {
            console.log('Am primit notificare :', payload)
            // toast(payload.notification?.title);
            if(payload.notification?.title) {
                toast(payload.notification.body, { position: "top-right", hideProgressBar: true, className: 'bg-primary text-white' });
                // toast.error(payload.notification.body, { position: "top-right", hideProgressBar: true, className: 'bg-primary text-white' })
            } else {
                //TODO: parse the freaking data 
            }
        })
    }, []);

    return <div>
        <ToastContainer />
    </div>;
};

export default Notification;
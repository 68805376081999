import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isCompetition, setIsCompetition] = useState<boolean>(false);
    const [competitionState, setCompetitionState] = useState<{ [key: string]: boolean }>({});
    const [isDanceSchoolDashboard, setIsDanceSchoolDashboard] = useState<boolean>(false);
    const [isDanceSchoolDetails, setIsDanceSchoolDetails] = useState<boolean>(false);

    // Apps
    const [isSalsaNama, setSalsaNama] = useState<boolean>(false);
    const [isBachataNama, setBachataNama] = useState<boolean>(false);


    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement;
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    const toggleCompetitionState = (competitionId: string) => {
        setCompetitionState((prevState) => ({
          ...prevState,
          [competitionId]: !prevState[competitionId],  // Toggle the state for this competition
        }));
      };

    const competitionSelector = createSelector(
        (state: any) => state.Competitions,
        (competitionState) => ({
            competitionList: competitionState.competitions
        })
    );

    const {  competitionList } = useSelector(competitionSelector);
    // console.log('Competition list: ', competitionList);
    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Competition') {
            setIsCompetition(false);
        }

    }, [
        history,
        iscurrentState,
        isDashboard,
        isCompetition
    ]);


    const competitionDashboardItem = {
        id: 'competitionDashboard',
        label: 'Dashboard',
        link: "/competitions/dashboard",
        parentId: "competitions"
      };

   

    const competitionSubItems = useMemo(() => { return[
        competitionDashboardItem,
        ...competitionList.
        filter((competition: any) => competition.isParentContest)
        .map((competition: any) => {
            // console.log('Competition name', competition.name);
            // console.log('Competition isParentContest', competition.isParentContest);
            if(competition.isParentContest) {
                const childCompetitions = competitionList.filter((child: any) => child.parentContest === competition._id);
                return {
                    id: competition._id,
                    label: competition.name,
                    link: "/#",
                    parentId: "competitions",
                    isChildItem: true,
                    click: function (e:any) {
                        e.preventDefault();
                        toggleCompetitionState(competition._id);
                    },
                    stateVariables: competitionState[competition._id],
                    childItems: [
                        {
                            id: `1-${competition._id}`,
                            label: "Dashboard",
                            link: `/competition/${competition._id}`,
                            parentId: competition._id
                        },
                        {
                            id: `2-${competition._id}`,
                            label: "Competitors",
                            link: `/competition/${competition._id}/competitors`,
                            parentId: competition._id
                        },
                        ...childCompetitions.map((child: any) => ({
                            id: child._id,
                            label: child.name,
                            link: "#",
                            parentId: competition._id,
                            isChildItem: true,
                            click: function (e:any) {
                                e.preventDefault();
                                toggleCompetitionState(child._id);
                            },
                            stateVariables: competitionState[child._id],
                            isLive : child.isLive,
                            badgeColor: "success",
                            badgeName: "Live",
                            childItems: [
                                {
                                    id: `1-${child._id}`,
                                    label: "Dashboard",
                                    link: `/competition/${child._id}`,
                                    parentId: child._id
                                }
                            ]
                        }))
                    ]
                };
            } else {
                return {
                    id: competition._id,
                    label: competition.name,
                    link: "/#",
                    parentId: "competitions",
                    isChildItem: true,  // It's a child competition
                    click: function (e: any) {
                        e.preventDefault();
                        toggleCompetitionState(competition._id);
                    },
                    stateVariables: competitionState[competition._id],
                    childItems: [
                        {
                            id: `1-${competition._id}`,
                            label: "Dashboard",
                            link: `/competition/${competition._id}`,
                            parentId: competition._id
                        }
                    ]
                };
            }
        })
    ]}, [competitionList, competitionState]);
        


    const menuItems: any = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Dashboards",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e: any) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "users",
                    label: "Users",
                    link: "/users",
                    parentId: "dashboard",
                    badgeColor: "success",
                    badgeName: "New"
                },
                {
                    id: "dance-school-dashboard",
                    label: "Dance Schools",
                    link: "/dance-schools",
                    parentId: "dashboard",
                    click: function (e: any) {
                        e.preventDefault();
                        setIsDanceSchoolDetails(!isDanceSchoolDetails);
                        setIscurrentState('Dance School');
                        updateIconSidebar(e);
                    },
                }, 
                {
                    id: "song-manager",
                    label: "Song Manager",
                    link: "/competition/songs",
                    parentId: "dashboard",
                }
            ],
        },
        {
            id: "competitions",
            label: "Competitions",
            icon: "ri-apps-2-line",
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setIsCompetition(!isCompetition);
                setIscurrentState('Competition');
                updateIconSidebar(e);
            },
            stateVariables: isCompetition,
            subItems: competitionSubItems
        }
    ];

    // console.log('Menu items: ', menuItems);
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer"
import firebase from 'firebase/compat/app';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

const fireBaseBackend : any= getFirebaseBackend();

export const userForgetPassword = (user : any, history : any) => async (dispatch : any) => {
  try {
        
    const response = await firebase.auth().sendPasswordResetEmail(user.email).then(() => { return true}).catch((error) => { return false});
      console.log(response);
      if (response) {
          dispatch(userForgetPasswordSuccess(
              "Reset link are sended to your mailbox, check there first"
          ))
      }
  } catch (forgetError) {
      dispatch(userForgetPasswordError(forgetError))
  }
}

const GlobalDebug = (function () {
    const savedConsole = console;
  
    /**
     * @param {boolean} debugOn
     * @param {boolean} [suppressAll=false]
     */
    return function (debugOn: boolean, suppressAll: boolean = false) {
      const suppress = suppressAll || false;
  
      if (debugOn === false) {
        // Suppress the default console functionality
        (console as any) = {};
        console.log = function () {};
  
        // Suppress all types of consoles if suppressAll is true
        if (suppress) {
          console.info = function () {};
          console.warn = function () {};
          console.error = function () {};
        } else {
          console.info = savedConsole.info;
          console.warn = savedConsole.warn;
          console.error = savedConsole.error;
        }
      } else {
        (console as any) = savedConsole;
      }
    };
  })();

export default GlobalDebug;
import React, { useState } from "react"
import { Row, Col, Card, CardBody, Modal, ModalHeader, Form, ModalBody, Input, Label, Button, ModalFooter } from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import vertication from "../../../assets/images/verification-img.png";

import CreatableSelect from 'react-select';
import UserDropdown from "./UserDropdown";
import { createDanceSchool } from "slices/thunks";
import CreateDanceSchoolModal from "./CreateDanceSchoolModal";


const CreateDanceSchool = () => {
    const dispatch: any = useDispatch();

    const [isCreateDanceSchool, setIsCreateDanceSchool] = useState<boolean>(false);
    const toggleCreateDanceSchool = () => {
        setIsCreateDanceSchool(!isCreateDanceSchool);
        validation.resetForm();
    };  


    const validation = useFormik({
        initialValues: {
            owner: {
                email: "",
                name: "",
                surname: "",
                phoneNumber: "",
                isCreating: false,
            },
            name: "",
            description: "",
            location: "",
            phoneNumber: "",
            businessType: "dance_school"
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Numele este obligatoriu"),
            owner: Yup.object().shape({
              email: Yup.string().email("Email invalid").when('isCreating', {
                is: true,
                then: (schema) => schema.required("Email este obligatoriu"),
                otherwise: (schema) => schema.notRequired(),
              }),
              name: Yup.string().when('isCreating', {
                is: true,
                then: (schema) => schema.required("Prenumele este obligatoriu"),
                otherwise: (schema) => schema.notRequired(),
              }),
              surname: Yup.string().when('isCreating', {
                is: true,
                then: (schema) => schema.required("Numele de familie este obligatoriu"),
                otherwise: (schema) => schema.notRequired(),

              }),
              phoneNumber: Yup.string().min(7).when('isCreating', {
                is: true,
                then: (schema) => schema.required("Numarul de telefon este obligatoriu"),
                otherwise: (schema) => schema.notRequired(),
              }),
              isCreating: Yup.boolean(),  // Ensure isCreating is part of owner
            }),
        }),
        onSubmit: (values) => {
            // console.log('Creating dance school');
            // console.log(values);
            validation.resetForm();
            toggleCreateDanceSchool();
            dispatch(createDanceSchool(values));
        }
    });

    return (
        <React.Fragment>
            <Row className="justify-content-center">
                <Col lg={6}>
                    <Card>
                        <CardBody> 
                            <div className="text-center">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <h4 className="mt-4 fw-semibold">Adauga Scoala de Dans</h4>
                                        <p className="text-muted mt-3">
                                            Nu exista nici o scoala de dans. Adauga una noua.
                                        </p>
                                        <div className="mt-4">
                                            <button 
                                                type="button"
                                                onClick={toggleCreateDanceSchool}
                                                className="btn btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                Adauga
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center mt-5 mb-2">
                                <Col sm={7} xs={8}>
                                    <img src={vertication} alt="" className="img-fluid" />
                                </Col>
                            </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                  {/* Dance School Modal */}
            <CreateDanceSchoolModal
                isOpen={isCreateDanceSchool}
                toggle={toggleCreateDanceSchool}
            />
        </React.Fragment>
    )
}

export default CreateDanceSchool;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export interface CompetitionState {
  loading: boolean;
  competitions: any[];
  error: string | null;
}

export const initialState: CompetitionState = {
  loading: false,
  competitions: [],
  error: null,
};

const competitionSlice = createSlice({
    name: "Competitions",
    initialState,
    reducers: {
      fetchCompetitionsRequest(state) {
        state.loading = true;
        state.error = null;
      },
      fetchCompetitionsSuccess(state, action: PayloadAction<any[]>) {
        state.loading = false;
        state.competitions = action.payload;
      },
      fetchCompetitionsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      createCompetitionRequest(state) {
        state.loading = true;
        state.error = null;
      },
      createCompetitionSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.competitions.push(action.payload);
      },
      createCompetitionFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      addToCompetitionRequest(state) {
        state.loading = true;
        state.error = null;
      },
      addToCompetitionSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        const user = {
          _id: action.payload.userId,
          name: action.payload.name,
          surname: action.payload.surname,
          phoneNumber: action.payload.phoneNumber,
        }

        const competition = state.competitions.find((c: any) => c._id === action.payload.competitionId);
        if(competition) {
          competition.participants.push(user);
        }
        toast.success("Competitor adaugat cu succes", { autoClose: 3000 });
      },  
      addToCompetitionFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      removeUserFromCompetitionRequest(state) {
        state.loading = true;
        state.error = null;
      },
      removeUserFromCompetitionSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        console.log(action.payload);
      // Replace the entire competition
        state.competitions = state.competitions.map((c) => 
          c._id === action.payload._id ? action.payload : c
        );

      },
      removeUserFromCompetitionFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
    },
  });
  
  export const {
    fetchCompetitionsRequest,
    fetchCompetitionsSuccess,
    fetchCompetitionsFailure,
    createCompetitionRequest,
    createCompetitionSuccess,
    createCompetitionFailure,
    addToCompetitionRequest,
    addToCompetitionSuccess,
    addToCompetitionFailure,
    removeUserFromCompetitionRequest,
    removeUserFromCompetitionSuccess,
    removeUserFromCompetitionFailure,
  } = competitionSlice.actions;
  
  export default competitionSlice.reducer;
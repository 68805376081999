import { Song } from "types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


export interface SongsState {
    songs: Song[];
    loading: boolean;
    error: string | null;
}

export const initialState: SongsState = {
    songs: [],
    loading: false,
    error: null,
}

const SongsSlice = createSlice({
    name: 'Songs',
    initialState,
    reducers: {
        fetchSongsRequest(state) {
            state.loading = true;
            state.error = null;
        },
        fetchSongsSuccess(state, action: PayloadAction<Song[]>) {
            // console.log(action.payload);
            state.songs = action.payload;
            state.loading = false;

        },
        fetchSongsError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
            toast.error("Unable to get songs");
        },
    }
});

export const {
    fetchSongsRequest,
    fetchSongsSuccess,
    fetchSongsError,
} = SongsSlice.actions;

export default SongsSlice.reducer;
import { getFirebaseBackend } from "helpers/firebase_helper";

interface ToastNotificationParams {
    title: string;
    description: string;
    status: 'success' | 'error' | 'info' | 'warning'; // You can adjust the types of status based on your specific use case
  }
  
  // Define the types for the native notification parameters
  interface NativeNotificationParams {
    title: string;
    body: string;
  }
  
  // Function to handle toast notifications
  export const toastNotification = ({ title, description, status }: ToastNotificationParams): void => {
    // Implement your toast notification logic
    console.log(`Toast Notification: ${title} - ${description} - ${status}`);
  };
  
  // Function to handle native notifications
  export const sendNativeNotification = ({ title, body }: NativeNotificationParams): void => {
    // Implement your native notification logic
    console.log(`Native Notification: ${title} - ${body}`);
  };

  export const requestBrowserPermission = async () => {
    Notification.requestPermission().then((permission) => {
    // console.log('Requested browser notificatoin permission and got : ', permission);

      if(permission === 'granted') {
        // getFirebaseBackend()?.geMessagingToken();
      } else {
        console.log('No browser notification permission :(');
        localStorage.removeItem("messagingToken");
        //TODO: implement server side polling 
      }
    });
  } 
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";
import { logoutUser } from "../slices/auth/login/thunk";
import { deleteDeviceToken } from "helpers/backend_helper";

const AuthProtected = (props: any) => {
  const dispatch: any = useDispatch();
  const { userProfile, loading } = useProfile();

  useEffect(() => {
    if (!loading && !userProfile) {
      const messagingToken = localStorage.getItem("messagingToken");
      if(messagingToken) deleteDeviceToken(messagingToken);
      dispatch(logoutUser());
    }
    if (userProfile?.token) {
      setAuthorization(userProfile.token);
    }
  }, [userProfile, loading, dispatch]);

  /*
    Navigate is un-auth access protected routes via url
  */
  if (!userProfile && !loading) {
    return <Navigate to="/login" />;
  }

  return <>{props.children}</>;
};

export default AuthProtected;
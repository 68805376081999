import React, { useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from 'react-redux';
import { Label, Input } from 'reactstrap';

interface UserDropdownProps {
    value: any; // You can replace 'any' with the correct type if known
    onChange: (value: any) => void;
    errors?: any;
    touched?: any;
    onBlur: (field: string) => void;  // Add onBlur prop

  }


const UserDropdown: React.FC<UserDropdownProps> = ({ value, onChange, errors, touched, onBlur }) => {
    const { userList } = useSelector((state: any) => state.Users);
    const [isCreating, setIsCreating] = useState(false); // Track new user creation state

    const userOptions = useMemo(() => {
        return userList.map((user: any) => ({ value: user._id, label: user.name + ' ' + user.surname }));
    }, [userList]);
    
      const handleCreate = (inputValue: string) => {
        const newUser = { value: inputValue, label: inputValue, isCreating: true };
        onChange(newUser); // Include isCreating in the new user object
        setIsCreating(true); // Show additional fields
      };

      const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        onBlur(`selectedUser.${e.target.name}`);  // Pass field name to set it as touched
      };

      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value: inputValue } = e.target;
        const currentValues = value && typeof value === 'object' ? value : {};
        onChange({ ...currentValues, [name]: inputValue, isCreating: true });
      };


    return (
        <div>
            <CreatableSelect
                isClearable
                options={userOptions}
                onChange={(selectedOption) => {
                    if (!selectedOption) {
                        onChange({isCreating: false});
                      console.log('Setting isCreating to false');
                      setIsCreating(false);  // Hide the additional fields when cleared
                    } else {
                        onChange(selectedOption);
                    }
                  }}
                onCreateOption={(inputValue) => {
                    const newUser = { value: inputValue, label: inputValue, isCreating: true };
                    onChange(newUser);
                    setIsCreating(true);
                }}
                value={value?.value ? value : null}  // Only pass the value if it exists
                placeholder="Selecteaza sau creeaza un nou utilizator..."
                formatCreateLabel= {(inputValue) => {
                    return  <p className='text-body mb-2'>Creeaza.. {inputValue}</p>
                }}
            />

            {/* Show additional fields for new user creation */}
            {isCreating && (
                <React.Fragment>  
                <div className='mb-3'/>
                <div className='mb-3 blockquote custom-blockquote blockquote-outline blockquote-primary rounded material-shadow mb-0'>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Name</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={value?.name || ''}
                            onBlur={handleBlur}  // Trigger onBlur when input loses focus
                            onChange={handleInputChange}
                            placeholder="Enter name" />
                        {touched?.name && errors?.name && <div className="text-danger">{errors.name}</div>}
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="surname" className="form-label">Surname</Label>
                        <Input
                            type="text"
                            name="surname"
                            onBlur={handleBlur}  // Trigger onBlur when input loses focus
                            value={value?.surname || ''}
                            onChange={handleInputChange}
                            placeholder="Numele de familie" />
                        {touched?.surname && errors?.surname && <div className="text-danger">{errors.surname}</div>}

                    </div>

                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email</Label>
                        <Input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onBlur={handleBlur}  // Trigger onBlur when input loses focus
                            value={value?.email || ''}
                            onChange={handleInputChange}
                            placeholder="Enter email" />
                        {touched?.email && errors?.email && <div className="text-danger">{errors.email}</div>}

                    </div>

                    <div className="mb-3">
                        <Label htmlFor="phoneNumber" className="form-label">Phone Number</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            name="phoneNumber"
                            onBlur={handleBlur}  // Trigger onBlur when input loses focus
                            value={value?.phoneNumber || ''}
                            onChange={handleInputChange}
                            placeholder="Enter phone number" />
                        {touched?.phoneNumber && errors?.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                    </div>
                </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default UserDropdown;
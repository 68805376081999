import { createDanceSchoolError, createDanceSchoolSuccess, fetchDanceSchoolsError, fetchDanceSchoolsRequest, fetchDanceSchoolsSuccess } from "./reducer";

import {
    createDanceSchool as createDanceSchoolApi,
    getDanceSchools as getDanceSchoolsApi
} from "../../helpers/backend_helper"
import { toast } from "react-toastify";


export const getDanceSchools = () => async (dispatch: any) => {
    try {
        const danceSchools = {
            type: "dance_school"
        };
        dispatch(fetchDanceSchoolsRequest());
        const response: any = await getDanceSchoolsApi(danceSchools);
        dispatch(fetchDanceSchoolsSuccess(response.danceSchools));
        return response;
    } catch (error: any) {
        dispatch(fetchDanceSchoolsError(error.message));
    }
    return {
        danceSchools: []
    }
}


export const createDanceSchool = (danceSchool: any) => async (dispatch: any) => {

    let danceSchoolData = {};

    if(danceSchool.owner.isCreating){
        danceSchoolData = {
            name: danceSchool.name,
            businessType: danceSchool.businessType,
            address: danceSchool.location,
            phoneNumber: danceSchool.phoneNumber,
            description: danceSchool.description,
            owner: {
                ...danceSchool.owner,
                isCreating: true
            }
        }
    } else {
        danceSchoolData = {
            name: danceSchool.name,
            description: danceSchool.description,
            address: danceSchool.location,
            phoneNumber: danceSchool.phoneNumber,
            owner: {
                id: danceSchool.owner.value,
                isCreating: false
            },
            businessType: danceSchool.businessType
        };
    }
    
    try {
        console.log('Making api call to create dance school', danceSchoolData);
        const response: any = await createDanceSchoolApi(danceSchoolData);
        dispatch(createDanceSchoolSuccess(response));
        return response
    } catch (error: any) {
        toast.error(error.message);
        dispatch(createDanceSchoolError(error.message));
    }
}
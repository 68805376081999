import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import List from "./List";
import { useSelector } from "react-redux";
import CreateCompetition from "../CreateCompetition";
import { bouncy } from 'ldrs'




const CompetitionsDashboard = () => {
    document.title = "Competitions | Dashboard";
    bouncy.register();
    
    const { competitions, loading: competitionsLoading, error: competitionsError } = useSelector((state: any) => state.Competitions);
    const { loading, user } = useSelector((state: any) => state.Login);


    if (loading || !user || competitionsLoading) {
        return (
            <div className="page-content">
            <Container fluid>
              <div className="page-content text-center">
                  <l-bouncy
                      size="75"
                      speed="1.3" 
                      color="orange" 
                  />
              </div>
            </Container>
        </div>
        );
    }

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Dashboard" pageTitle="Competitions" />
                {competitions && competitions.length > 0 ? <List /> : <CreateCompetition />}
            </Container>
        </div>
    );
};

export default CompetitionsDashboard;
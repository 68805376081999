//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { profileSuccess } from "../profile/reducer";

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';
import { fetchInitialData } from "helpers/service/dataService";

export const loginUser = (user : any, authRememberCheck: boolean, history : any) => async (dispatch : any) => {
  try {
    let response;
    const fireBaseBackend : any = getFirebaseBackend();
    console.log(user);
    response = fireBaseBackend.loginUser(
      user.email,
      user.password,
      authRememberCheck
    );

    var data = await response;

    if (data) {
      
      dispatch(loginSuccess(data));
      dispatch(profileSuccess(data));
      console.log("Ma duc sa iau toate datele dupa autentificare");
      dispatch(fetchInitialData());
      history('/competitions/dashboard')

    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch : any) => {
  try {
    sessionStorage.clear();
    localStorage.clear();
    // unregisterServiceWorker();
    let fireBaseBackend : any = getFirebaseBackend();
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      await fireBaseBackend.logout;
      console.log('Calling log-out user from auth/login/logoutUser');
      dispatch(logoutUserSuccess(true));
    } else {
      dispatch(logoutUserSuccess(true));
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};




export const resetLoginFlag = () => async (dispatch : any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const registerMessagingServiceWorker = ()  => {
  // console.log('Registering service worker')
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
      .then((registration) => {
        // console.log(registration);
        // console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Error registering service worker:', error);
      });
  }
};
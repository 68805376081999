import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import Scss
import './assets/scss/themes.scss';
import { createSelector } from "reselect";

//imoprt Route
import Route from './Routes';
import { serializeFirebaseUser } from 'helpers/firebase_helper';
import { loginSuccess, logoutUserSuccess } from 'slices/auth/login/reducer';
import firebase from 'firebase/compat/app';
import { registerUserLogout } from 'slices/auth/register/reducer';
import { deleteDeviceToken } from 'helpers/backend_helper';
import { fetchInitialData } from 'helpers/service/dataService';
import GlobalDebug from 'helpers/remove-logs';
import "pure-uikit/dist/themes/light.css";
import { setBasePath } from "pure-uikit/dist/utilities/base-path";

setBasePath("https://cdn.jsdelivr.net/npm/pure-uikit@1.2.15/cdn/");

function App() {
  const dispatch:any = useDispatch();

  const logoutData = createSelector(
    (state) => state.Login,
    (isUserLogout) => isUserLogout.isUserLogout,
    (isUserLoaded) => isUserLoaded.user
  );

  // const isUserLogout = useSelector(logoutData);
  const isUserLoaded = useSelector(logoutData);
  
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(true);
  }, []);

  useEffect(() => {

    // Subscribe to Firebase auth state changes
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const serializedUser = await serializeFirebaseUser(user);
        // User is logged in, update the Redux state
        if(process.env.NODE_ENV === 'development') {
          // console.log('isUserLoaded ? ', isUserLoaded);
          // console.log('serializedUser ? ', serializedUser);
          localStorage.setItem("token", serializedUser?.token ?? '');
        }
        if(isUserLoaded && isUserLoaded.token !== serializedUser?.token) {
          console.log('Fac dispatch loginSuccess din App.tsx');
          dispatch(loginSuccess(serializedUser));
        }
        console.log('Fac dispatch fetchInitialData din App.tsx');
        dispatch(fetchInitialData());
      } else {
        // User is logged out, update the Redux state
        const messagingToken = localStorage.getItem("messagingToken");
        if(messagingToken) deleteDeviceToken(messagingToken);
        dispatch(logoutUserSuccess(true));
        dispatch(registerUserLogout());
      }
    });
  }, [dispatch, isUserLoaded]);
  
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;

import React from "react";



const DanceSchoolDetails = ({danceSchool}: any) => {
    console.log("Afisez detaliile scolii de dans ", danceSchool);
    return (
        <React.Fragment>
            <h1>Dance School Details</h1>
        </React.Fragment>
    )
}

export default DanceSchoolDetails;
import React, { useState } from "react";
import { Row, Col, TabContent, TabPane, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import ParticipantsTab from "./ParticipantsTab";
import OverviewTab from "./OverviewTab";
import salsaNamaLogo from "../../../../assets/images/salsanama-large.png";
import bachataNamaLogo from "../../../../assets/images/bachatanama-large.png";
import PBadge from 'pure-uikit/dist/react/badge';

import classnames from 'classnames';
import { useLocation, useParams } from "react-router-dom";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

const Section = ({competition}: any) => {
    console.log('Section - competition ', competition);

    const query = useQuery();
    const tab = query.get('tab');
    console.log('Section - tab ', tab);

    //Tab 
    const [activeTab, setActiveTab] = useState<any>('1');
    const toggleTab = (tab:any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-info-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center">
                                            <div className="col-md-auto">
                                                <div className="avatar-md rounded">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <img src={String(competition.name).toLocaleLowerCase().includes('salsa') ? salsaNamaLogo : bachataNamaLogo} alt="" className="avatar-md2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <h4 className="fw-bold">{competition.name}</h4>
                                                        <div className="vr"></div>
                                                        {competition.isLive && (
                                                            <PBadge variant="danger" pill pulse>
                                                                Live
                                                            </PBadge>
                                                        )}
                                                    </div>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div><i className="ri-building-line align-bottom me-1"></i> {competition.location ? competition.location : 'N/A'}</div>
                                                        <div className="vr"></div>
                                                        <div>Data : <span className="fw-medium">{new Date(competition.date).toDateString()}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>
                                <Nav className="nav-tabs-custom border-bottom-12" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                            onClick={() => { toggleTab('1'); }}
                                            href="#">
                                            Overview
                                        </NavLink>
                                    </NavItem>
                                    {!competition.isParentContest && (
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                                onClick={() => { toggleTab('2'); }}
                                                href="#">
                                                Participants
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xxl={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        <TabPane tabId="1">
                            <OverviewTab competition={competition}/>
                        </TabPane>
                        {!competition.isParentContest && (
                            <TabPane tabId="2">
                                <ParticipantsTab comp={competition}/>
                            </TabPane>
                        )}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );  
}

export default Section;
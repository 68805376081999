import React, { useEffect } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useSelector, useDispatch } from 'react-redux';


import {
  fetchCompetitions as onCompetitionsData
} from '../../slices/competitions/thunk'

const DashboardEcommerce = () => {
  document.title = "SalsaNama | Dashboard";
  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(onCompetitionsData());
}, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Ecommerce" pageTitle="Dashboard" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;


import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UsersState {
    loading: boolean;
    userList: any[];
    error: string | null;
  }
  
  export const initialState: UsersState = {
    loading: false,
    userList: [],
    error: null,
  };

const UserSlice = createSlice({
    name: 'Users',
    initialState,
    reducers: {
        fetchUsersRequest(state) {
            state.loading = true;
            state.error = null;
        },
        fetchUsersSuccess(state, action: PayloadAction<any[]>) {
            state.loading = false;
            state.userList = action.payload;  // Handle the response as is
            state.error = null;
          },
        fetchUsersError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload
        },
        addRoleToUserRequest(state) {
            state.loading = true;
            state.error = null;
        },
        addRoleToUserSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            const newUser = {
              _id: action.payload._id,
              name: action.payload.name,
              surname: action.payload.surname,
              danceSchool: action.payload.danceSchool,
              phoneNumber: action.payload.phoneNumber,
              competitions: action.payload.competition,
              roles: action.payload.roles,
              firebaseUid: action.payload.firebaseUid
            }
            state.userList = state.userList.map((user) => user._id === newUser._id ? newUser : user);
            // state.userList = [...state.userList, newUser];  // Handle the response as is
            state.error = null;
          },
        addRoleToUserError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload
        },
        removeRoleFromUserRequest(state) {
            state.loading = true;
            state.error = null;
        },
        removeRoleFromUserSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            const newUser = {
              _id: action.payload._id,
              name: action.payload.name,
              surname: action.payload.surname,
              danceSchool: action.payload.danceSchool,
              phoneNumber: action.payload.phoneNumber,
              competitions: action.payload.competition,
              roles: action.payload.roles,
              firebaseUid: action.payload.firebaseUid
            }
            state.userList = state.userList.map((user) => user._id === newUser._id ? newUser : user);
            state.error = null;
        },
        removeRoleFromUserError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload
        },
        createUserRequest(state) {
            state.loading = true;
            state.error = null;
        },
        createUserSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.userList = [...state.userList, action.payload];
            state.error = null;
        },
        createUserError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload
        }
    }
});

export const {
    fetchUsersRequest,
    fetchUsersSuccess,
    fetchUsersError,
    addRoleToUserRequest,
    addRoleToUserSuccess,
    addRoleToUserError,
    removeRoleFromUserRequest,
    removeRoleFromUserSuccess,
    removeRoleFromUserError,
    createUserRequest,
    createUserSuccess,
    createUserError
} = UserSlice.actions

export default UserSlice.reducer;
import BreadCrumb from "Components/Common/BreadCrumb";
import RoleBadges from "Components/Common/RoleBadges";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { createSelector } from "reselect";
import { Role } from "types";
import RoleSelector from 'Components/Common/RoleSelector';
import TableContainer from "Components/Common/TableContainer";
import { Table as ReactTable, useReactTable } from '@tanstack/react-table';
import { createUser, updateUserRoles } from "slices/thunks";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from 'yup';

// Define available roles
const availableRoles: any[] = [
    'admin',
    'superadmin',
    'competitor',
    'owner',
    'judge',
    'coach',
  ];


const availableRolesOptions = availableRoles.map((role) => ({ value: role, label: role.toLocaleUpperCase() }));
const UserList = () => {
    
    document.title = "SalsaNama | Users";
    const dispatch = useDispatch();

    const [selectedRoles, setSelectedRoles] = useState<{ [key: string]: any }>({});
    const selectUserState = (state: any) => state.Users;
    // State for search term
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Pagination state
    const [currentPage, setCurrentPage] = useState<number>(1);
    const usersPerPage = 10; // Adjust as needed

    const userSelector = createSelector(
        selectUserState,
        (userState) => ({
          userList: userState.userList,
          isLoadingUsers: userState.loading,
          userError: userState.error,
        })
      );

    const { userList, isLoadingUsers, userError } = useSelector(userSelector);

    
    const [modal, setModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<string>('');
    const [roleToRemove, setRoleToRemove] = useState<{ userId: string; role: Role } | null>(null);

    const toggleModal = () => setModal(!modal);

    const confirmRemoveRole = (userId: string, role: Role) => {
    setRoleToRemove({ userId, role });
    toggleModal();
    };

    const handleConfirmRemove = () => {
    if (roleToRemove) {
        handleRemoveRole(roleToRemove.userId, roleToRemove.role);
        setRoleToRemove(null);
        toggleModal();
    }
    };


    const handleAddRole = (userId: string) => {
        const role = selectedRoles[userId];
        if (role && !userList.find((user: any) => user._id === userId)?.roles.includes(role)) {
        const newRoles = {
            roles: [...userList.find((user: any) => user._id === userId)?.roles, role]
        };
          dispatch(updateUserRoles('add', userId, newRoles) as any);
          setSelectedRoles((prev) => ({ ...prev, [userId]: '' as Role }));
        }
      };

      const handleRemoveRole = (userId: string, role: any) => {
        if(role === 'superadmin' || role === 'admin' || role === 'owner') {
            toast.error("Superadmin, Admin si Owner momentan nu pot fi scoase. Vorbeste cu Daniel", { autoClose: 5000 });
            return;
        }
        // Find the user in the userList by their ID
        const user = userList.find((user: any) => user._id === userId);
        
        // Check if the user exists and has the role to be removed
        if (user && user.roles.includes(role)) {
            // Create a new roles array without the role to be removed
            const updatedRoles = {
            roles: user.roles.filter((r: Role) => r !== role)
            };
            
            // Dispatch the updateUserRoles action with the 'remove' operation
            dispatch(updateUserRoles('remove', userId, updatedRoles) as any);
            setRoleToRemove(null);
        }
      };

      const handleRoleChange = (userId: string, role: any) => {
        setSelectedRoles((prev) => ({ ...prev, [userId]: role }));
      };

    // Filter out users with firebaseUid as null
    const filteredUsers = useMemo(() => {
        return userList.filter((user: any) => user.firebaseUid !== null);
    }, [userList]);
    
    // Further filter users based on search term
    const searchedUsers = useMemo(() => {
        if (!searchTerm.trim()) return filteredUsers;
        const lowerSearch = searchTerm.toLowerCase();
        return filteredUsers.filter(
        (user: any) =>
            user.name.toLowerCase().includes(lowerSearch) ||
            user.surname.toLowerCase().includes(lowerSearch)
        );
    }, [filteredUsers, searchTerm]);

    // Calculate total pages
    const totalPages = Math.ceil(searchedUsers.length / usersPerPage);

    // Get current users based on pagination
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = searchedUsers.slice(indexOfFirstUser, indexOfLastUser);

    // Handle page change
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    // Generate pagination items
    const paginationItems = useMemo(() => {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
        <PaginationItem active={number === currentPage} key={number}>
            <PaginationLink onClick={() => paginate(number)}>
            {number}
            </PaginationLink>
        </PaginationItem>
        );
    }
    return items;
    }, [totalPages, currentPage]);


    const columns = useMemo(() => [
        {
            header: 'Name',
            id: 'fullName',
            accessorFn: (row: { name: any; surname: any; }) => `${row.name} ${row.surname}`,
            enableColumnFilter: false,
        },
        {
            header: 'Telefon',
            accessorKey: 'phoneNumber',
            enableColumnFilter: false,
        },
        {
            header: 'Roles',
            accessorKey: 'currentRoles',
            enableColumnFilter: false,
            accessorFn: (row: { roles: any; _id: any; }) => row.roles,
            cell: ({row}: any) => {
                return (
                    <RoleBadges roles={row.original.roles} onRemove={(role: any) => handleRemoveRole(row.original._id, role)} />        
                )

            }
        },
        {
            header: 'Actions',
            accessorKey: '_id',
            id: 'actions',
            enableColumnFilter: false,
            cell: ({row}: any) => {
                return (
                    <RoleSelector
                        userId={row.original._id}
                        availableRoles={availableRoles.filter((role) => !row.original.roles.includes(role))}
                        selectedRole={selectedRoles[row.original._id] || ''}
                        onChange={handleRoleChange}
                        onAdd={handleAddRole}
                    />
                )
            }
        },
    ], [selectedRoles, userList]);



    const userForm: any = useFormik({
        initialValues: {
            name: '',
            surname: '',
            phoneNumber: '',
            email: '',
            roles: [],
            type: 'internal'
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Prenumele este obligatoriu'),
            surname: Yup.string().required('le este obligatoriu'),
            phoneNumber: Yup.string().required('Telefon este obligatoriu').min(10, 'Telefonul trebuie sa aiba minim 10 caractere'),
            email: Yup.string().email('Email invalid').required('Email este obligatoriu'),
            roles: Yup.array().required('Rolurile sunt obligatorii').min(1, 'Trebuie sa ai minim 1 rol'),
        }),
        onSubmit: (values) => {
            dispatch(createUser(values) as any);
            console.log(values);
            toggleModal();
            userForm.resetForm();
        }
    });

    
    const customStyles = {
        multiValue: (styles : any, { data } : any) => {
            return {
              ...styles,
              backgroundColor: "#3762ea",
            };
          },
          multiValueLabel: (styles : any, { data } : any) => ({
            ...styles,
            backgroundColor : "#405189" ,
            color: "white",
          }),
          multiValueRemove: (styles : any, { data } : any) => ({
            ...styles,
            color: "white",
            backgroundColor : "#405189" ,
            ':hover': {
              backgroundColor: "#405189" ,
              color: 'white',
            },
          }),
    }

      return (
        <React.Fragment>
            <div className="page-content">
            <Modal backdrop="static" keyboard={false} isOpen={modal} toggle={toggleModal} centered={true}>
                <ModalHeader className="bg-light p-3" toggle={toggleModal}>
                    <h5>Adauga Utilizator</h5>
                </ModalHeader>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    userForm.handleSubmit();
                    return false;
                }}>
                    <ModalBody>
                            <FormGroup>
                                <Label for="name">Prenume</Label>
                                <Input type="text" id="name" name="name" value={userForm.values.name} onChange={userForm.handleChange} onBlur={userForm.handleBlur} />
                                {userForm.touched.name && userForm.errors.name ? (
                                    <div className="text-danger">{userForm.errors.name}</div>
                                ) : null}
                            </FormGroup>
                            <FormGroup>
                                <Label for="surname">Nume</Label>
                                <Input type="text" id="surname" name="surname" value={userForm.values.surname} onChange={userForm.handleChange} />
                                {userForm.touched.surname && userForm.errors.surname && <div className="text-danger">{userForm.errors.surname}</div>}
                            </FormGroup>
                            <FormGroup>
                                <Label for="phoneNumber">Telefon</Label>
                                <Input type="text" id="phoneNumber" name="phoneNumber" value={userForm.values.phoneNumber} onChange={userForm.handleChange} />
                                {userForm.errors.phoneNumber && <div className="text-danger">{userForm.errors.phoneNumber}</div>}
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="text" id="email" name="email" value={userForm.values.email} onChange={userForm.handleChange} />
                                {userForm.errors.email && <div className="text-danger">{userForm.errors.email}</div>}
                            </FormGroup>
                            <FormGroup>
                                <Label for="roles">Roluri</Label>
                                <Select
                                    id="roles"
                                    name="roles"
                                    isMulti={true}
                                    isClearable={true}
                                    styles={customStyles}
                                    options={availableRolesOptions}
                                    value={availableRolesOptions.filter((role) => userForm.values.roles.includes(role.value))}
                                    onChange={(selectedOptions: any) => {
                                        const selectedRoles = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
                                        userForm.setFieldValue('roles', selectedRoles);
                                    }}
                                    placeholder="Selecteaza rolurile"
                                />
                                {userForm.errors.roles && <div className="text-danger">{userForm.errors.roles}</div>}
                            </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                        <Button color="danger" type="button" onClick={toggleModal}>Inchide</Button>
                        <Button color="success" type="submit" disabled={!userForm.isValid}>Adauga</Button>
                    </div>
                    </ModalFooter>
                </Form>
            </Modal>
            <Row>
                <Col lg={12}>
                    <Card id="participants-card">
                        <CardHeader className="border-0">
                            <div className="d-md-flex align-items-center">
                                <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                                    Lista Utilizatorilor
                                </h5>
                                <div className="flex-shrink-0">
                                    <Button
                                        color="success"
                                        type="button"
                                        className="add-btn"
                                        id="create-btn"
                                        onClick={() => {
                                            userForm.resetForm();
                                            toggleModal();
                                        }}
                                        >
                                        <i className="ri-add-line align-bottom me-1"></i>{" "}
                                        Adauga Utilizator
                                    </Button>
                                </div>
                            </div>
                            <Row className="g-4 align-items-center">
                                <Col sm={3}>
                                    <div className="col-sm">
                                        <div className="d-flex">
                                            <div className="search-box me-2">
                                                <Input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="search-bar-1"
                                                    placeholder="Search member..." 
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="pt-3">
                            <div>
                                {currentUsers.length === 0 ? (
                                    <div className="text-center page-content">
                                        <div className="row">
                                            <Row><h5>Lista Utilizatorilor</h5></Row>
                                        </div>
                                        <p>Nu există utilizatori.</p>
                                    </div>
                                ) : (
                                    <TableContainer
                                        columns={columns}
                                        data={currentUsers}
                                    />
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
      </React.Fragment>
    );
};

export default UserList;
import React, { useMemo, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import CompetitionListTable from "./CompetitionListTable";
import { Calendar, RadioGroup, Radio, Toggle, VStack, HStack, Divider } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import 'rsuite/Radio/styles/index.less';
import 'rsuite/Toggle/styles/index.less';
import 'rsuite/Divider/styles/index.less';

interface EventDetail {
    id: number;
    title: string;
    date: Date;
    time: string; // Format: 'HH:MM'
    description: string;
}


const OverviewTab = ({competition}: any) => {
        // Mock event details
        const mockEventDetails: EventDetail[] = [
            {
                id: 1,
                title: "Opening Ceremony",
                date: new Date(2024, 3, 15), // April 15, 2024
                time: "09:00",
                description: "The official opening ceremony of the competition.",
            },
            {
                id: 2,
                title: "Dance Workshop",
                date: new Date(2024, 3, 15), // April 15, 2024
                time: "11:00",
                description: "A workshop focusing on advanced dance techniques.",
            },
            {
                id: 3,
                title: "Semi-Final Round",
                date: new Date(2024, 4, 20), // May 20, 2024
                time: "14:00",
                description: "Semi-final performances by top competitors.",
            },
            {
                id: 4,
                title: "Final Round",
                date: new Date(2024, 5, 10), // June 10, 2024
                time: "16:00",
                description: "The final competition round to determine the winners.",
            },
        ];
    
        // State for selected date and events
        const [selectedDate, setSelectedDate] = useState<Date | null>(null);
        const [eventsForSelectedDate, setEventsForSelectedDate] = useState<EventDetail[]>([]);



    const [weekStart, setWeekStart] = React.useState(0);
    const [isoWeek, setIsoWeek] = React.useState(false);
    const [showWeekNumbers, setShowWeekNumbers] = React.useState(true);


    ////
    const selectCompetitionState = (state: any) => state.Competitions;
        const competitionSelector = useMemo(() =>  createSelector(
        selectCompetitionState,
        (competitionState) => ({
            competitionsList: competitionState.competitions,
            isLoadingCompetition: competitionState.loading,
            competitionError: competitionState.error,
        })
    ), [competition]);

    const { competitionsList } = useSelector(competitionSelector);

    const childCompetitions = useMemo(() => {
        if(competition.isParentContest){
            return competitionsList.filter((comp: any) => comp.parentContest === competition._id);
        }
        return [];
    }, [competitionsList, competition]);

    const combinedParticipants = useMemo(() => {
        if(!competition.isParentContest){
            return competition.participants;
        }

        const participantsMap: { [key: string]: any } = {};

        // Add participants from the main competition
        competition.participants.forEach((participant: any) => {
            participantsMap[participant._id] = participant;
        });

        // Add participants from child competitions
        childCompetitions.forEach((comp: any) => {
            comp.participants.forEach((participant: any) => {
                participantsMap[participant._id] = participant;
            });
        });

        // Convert the map back to an array
        return Object.values(participantsMap);
    }, [competition, childCompetitions]);

    const mockEventDates: Date[] = [
        new Date(2024, 3, 15), // April 15, 2024
        new Date(2024, 4, 20), // May 20, 2024
        new Date(2024, 5, 10), // June 10, 2024
    ];

    // Helper function to compare two dates (ignores time)
    const isSameDay = (date1: Date, date2: Date): boolean => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };


    const renderDateCell = (date: Date) => {
        // Check if the current date is in the mockEventDates array
        const hasEvent = mockEventDates.some(eventDate => isSameDay(eventDate, date));
    
        return (
            <div style={{ position: 'relative' }}>
                <span>{date.getDate()}</span>
                {hasEvent && (
                    <span
                        style={{
                            position: 'absolute',
                            bottom: 2,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: 6,
                            height: 6,
                            borderRadius: '50%',
                            backgroundColor: 'red',
                        }}
                    />
                )}
            </div>
        );
    };

    // Handle date selection on the Calendar
    const handleSelectDate = (date: Date) => {
        setSelectedDate(date);

        // Filter events that match the selected date
        const filteredEvents = mockEventDetails.filter(event =>
            isSameDay(event.date, date)
        );

        // Sort events by time
        const sortedEvents = filteredEvents.sort((a, b) => {
            const [hourA, minuteA] = a.time.split(':').map(Number);
            const [hourB, minuteB] = b.time.split(':').map(Number);
            return hourA - hourB || minuteA - minuteB;
        });

        setEventsForSelectedDate(sortedEvents);
    };

    // DayView component to display events for the selected date
    const DayView = () => {
        if (!selectedDate) {
            return <p>Select a date to view events.</p>;
        }

        if (eventsForSelectedDate.length === 0) {
            return <p>No events scheduled for this date.</p>;
        }

        return (
            <div>
                <h5>Events on {selectedDate.toDateString()}</h5>
                {eventsForSelectedDate.map(event => (
                    <Card key={event.id} className="mb-2">
                        <CardBody>
                            <h6>{event.time} - {event.title}</h6>
                            <p>{event.description}</p>
                        </CardBody>
                    </Card>
                ))}
            </div>
        );
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={3}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Competitori unici</p>
                                    <h2 className="mt-4 ff-secondary cfs-22 fw-semibold">
                                        <span className="counter-value">
                                            <CountUp
                                                start={0}
                                                end={combinedParticipants.length > 0 ? combinedParticipants.length : competition.participants.length}
                                                decimals={0}
                                                duration={1}
                                            />
                                        </span>
                                    </h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="users"
                                                className="text-info"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>   
                    </Col>  
                    </Row>
                    {/* Calendar and DayView */}
                    {/*  */}

            {competition.isParentContest && (
                <React.Fragment>
                    <Row>
                        <Col xxl={12}>
                            <CompetitionListTable competition={competition} />
                        </Col>
                    </Row>
                  
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default OverviewTab;



import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

//AuthenticationInner pages
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
import Error500 from '../pages/AuthenticationInner/Errors/Error500';
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";



//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import SalsaNamaLive from "pages/Competitions/SalsaNama/Live";
import BachataNamaLive from "pages/Competitions/BachataNama/Live";
import CompetitionUserList from "pages/Competitions/Competition/UserList";
import CompetitionsDashboard from "pages/Competitions/Dashboard/CompetitionsDashboard";
import CompetitionDashboard from "pages/Competitions/Competition/Dashboard";
import DanceSchoolDashboard from "pages/DanceSchool/Dashboard";
import DanceSchoolDetails from "pages/DanceSchool/Details";
import SongManager from "pages/Songs/SongManager";
import UserList from "pages/Users/UserList/UserList";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/users", component: <UserList/> },
  { path: "/dance-schools", component: <DanceSchoolDashboard/> },
  { path: "/dance-school/:danceSchoolId", component: <DanceSchoolDetails /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/competitions/dashboard", component: <CompetitionsDashboard/> },
  { path: "/competition/:competitionId", component: <CompetitionDashboard/>},
  { path: "/competition/:competitionId/competitors", component: <CompetitionUserList />},
  { path: "/competition/songs", component: <SongManager/>},

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/competitions/dashboard" />,
  },
  { path: "*", component: <Navigate to="/competitions/dashboard" /> },
];

const publicRoutes : any= [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages

  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-offline", component: <Offlinepage /> },
  { path: "/salsanama-live", component: <SalsaNamaLive/>},
  { path: "/bachatanama-live", component: <BachataNamaLive/>}

];

export { authProtectedRoutes, publicRoutes };
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown } from 'reactstrap';
import { createSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import moment from "moment";

//redux
import { useSelector, useDispatch } from 'react-redux';
import {
    createCompetition,
    fetchCompetitions 
} from '../../../../slices/competitions/thunk';
import DeleteModal from 'Components/Common/DeleteModal';
import { bouncy } from 'ldrs'
import FeatherIcon from "feather-icons-react";
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

const List = () => {
    bouncy.register();
    const [isCreateCompetition, setIsCreateCompetition] = useState<boolean>(false);
    const toggleCreateCompetition = () => setIsCreateCompetition(!isCreateCompetition);

    const dispatch: any = useDispatch();
    const competitionDashboarData = createSelector(
        (state: any) => state.Competitions,
        (competitionsState) => ({
            competitionsList: competitionsState.competitions,
            competitionsLoading: competitionsState.loading,
        })
    );
    const validation = useFormik({
        initialValues: {
            name: "",
            description: "",
            location: "",
            date: new Date(),
            isParentContest: true,
            parentCompetitionId: "", // New field

        },
        validationSchema: Yup.object().shape({  
            name: Yup.string().required("Numele este obligatoriu"),
            description: Yup.string().required("Descrierea este obligatorie"),
            location: Yup.string().required("Locatia este obligatorie"),
            date: Yup.date().required("Ziua este obligatorie"),
            isParentContest: Yup.boolean().required("Tipul de competitie este obligatoriu"),
            // parentCompetitionId: Yup.string().when('isParentContest', {
            //     is: false,
            //     then: Yup.string().required("Selectați o competiție părinte"),
            //     otherwise: Yup.string().notRequired(),
            // }),
        }),
        onSubmit: (values) => {
            const payload = {
                name: values.name,
                description: values.description,
                location: values.location,
                date: values.date,
                isParentContest: values.isParentContest,
                parentContestId: values.isParentContest ? null : values.parentCompetitionId,
            };
            console.log(payload);
            dispatch(createCompetition(payload));
            validation.resetForm();
            toggleCreateCompetition();
        }
    });


    const { competitionsList, competitionsLoading } = useSelector(competitionDashboarData);
    console.log('Competition List ', competitionsList);
    const [competition, setCompetition] = useState([]);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);


    useEffect(() => {
        setCompetition(competitionsList);
    }, [competitionsList]);

    const onClickData = (project:any) => {
        setCompetition(competition);
        setDeleteModal(true);
    };

    const handleDeleteProjectList = () => {
        if (competition) {
            // dispatch(onDeleteProjectList(project)); //TODO: delete competition
            setDeleteModal(false);
        }
    };
    
    const activebtn = (ele:any) => {
        if (ele.closest("button").classList.contains("active")) {
            ele.closest("button").classList.remove("active");
        } else {
            ele.closest("button").classList.add("active");
        }
    };

    // Optionally, filter competitions to include only parent competitions
    const parentCompetitions = competitionsList.filter((comp: any) => comp.isParentContest);

    return (
        <React.Fragment>
            {competitionsLoading ? <Loader /> : 
                <React.Fragment>
                    <ToastContainer closeButton={false} />
                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={() => handleDeleteProjectList()}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <Row className="g-4 mb-3">
                        <Col lg={12}>
                        <div className="d-md-flex align-items-center">
                    <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                      Competitions
                    </h5>
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-1 flex-wrap">
                        <Button
                          color="success"
                          type="button"
                          className="add-btn"
                          id="create-btn"
                          onClick={toggleCreateCompetition}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Creeaza competitie
                        </Button>
                      </div>
                    </div>
                  </div>                       
                   </Col>
                    </Row>
                    <div className="row">
                        {(parentCompetitions || []).map((competition:any, index:any) => {
                            // Get child competitions for the current parent
                            const childCompetitions = competitionsList.filter(
                                (comp: any) => comp.parentContest === competition._id
                            );
                            return (
                            <React.Fragment key={index}>
                                <Col xxl={3} sm={6} className="project-card">
                                    <Card>
                                        <CardBody>
                                            <div className="p-3 mt-n3 mx-n3 bg-info-subtle rounded-top">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-0 fs-14"><Link to={`/competition/${competition._id}`} className="text-body">{competition.name}</Link></h5>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div className="d-flex gap-1 align-items-center my-n2">
                                                            <button type='button' className='btn avatar-xs mt-n1 p-0 favorite-btn material-shadow-none'>
                                                                {/* <span className='avatar-title bg-transparent fs-15'>
                                                                    <i className='ri-star-fill'></i>
                                                                </span> */}
                                                            </button>
                                                            <UncontrolledDropdown direction='down' flip="true">
                                                                <DropdownToggle tag="button" className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 material-shadow-none">
                                                                    <FeatherIcon icon="more-horizontal" className="icon-sm" />
                                                                </DropdownToggle>

                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem href={`/competition/${competition._id}`}><i className="ri-eye-fill align-bottom me-2 text-muted"></i> View</DropdownItem>
                                                                    {/* <DropdownItem href="apps-projects-create"><i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit</DropdownItem> */}
                                                                    <div className="dropdown-divider"></div>
                                                                    <DropdownItem href="#" onClick={() => onClickData(competition)} data-bs-toggle="modal" data-bs-target="#removeProjectModal"><i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remove</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="py-3">
                                                <Row className="gy-3">
                                                    <Col xs={6}>
                                                        <div>
                                                            <p className="text-muted mb-1">Status</p>
                                                            <div className="fs-12 badge bg-danger-subtle text-danger">Not Started</div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <div>
                                                            <p className="text-muted mb-1">Data</p>
                                                            <h5 className="fs-14">{new Date(competition.date).toDateString().split(',')[0]}</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="d-flex align-items-center mt-3">
                                                    <p className="text-muted mb-0 me-2">Categorii :</p>
                                                    <div className="avatar-group">
                                                        {childCompetitions.length > 0 ? (
                                                            childCompetitions.map((child: any ) => (
                                                                <Link 
                                                                to={`/competition/${child._id}`} 
                                                                className="avatar-group-item material-shadow"
                                                                data-bs-toggle="tooltip" 
                                                                data-bs-trigger="hover" 
                                                                data-bs-placement="top" 
                                                                title={child.name} 
                                                                key={child._id}
                                                                >
                                                                    <div className="avatar-xs">
                                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                                                            {child.name.charAt(0).toUpperCase()} 
                                                                        </span>
                                                                    </div>
                                                                </Link>
                                                            ))
                                                        ) : (
                                                            <div className="fs-12 badge bg-danger-subtle text-danger">No Categories</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Modal
                                isOpen={isCreateCompetition}
                                toggle={toggleCreateCompetition}
                                centered={true}
                                size="lg">
                                <ModalHeader className="bg-light p-3" toggle={toggleCreateCompetition}>
                                    Adauga competitie
                                </ModalHeader>
                                <Form className="tablelist-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    <ModalBody>
                                        <Input type="hidden" id="id-field" />
                                            <div className="mb-3 d-none" id="modal-id">
                                                <Label type="text" className="form-label">Competitie</Label>
                                                <Input type="text" className="form-control" id="competitionId" placeholder="Competition" readOnly />
                                            </div>

                                            <div className="text-center">
                                                <div className="position-relative d-inline-block">
                                                    <div className="position-absolute bottom-0 end-0">
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="numeleCompetitiei" className="form-label"> Numele competitiei </Label>
                                                <Input type="text" className="form-control" id="numeleCompetitiei" placeholder="Numele competitiei" 
                                                name="name" onChange={validation.handleChange} 
                                                onBlur={validation.handleBlur} 
                                                value={validation.values.name}
                                                />
                                                {validation.touched.name && validation.errors.name ? (
                                                    <div className="text-danger">{validation.errors.name}</div>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="descriereaCompetitiei" className="form-label"> Descrierea competitiei </Label>
                                                <Input type="text" className="form-control" id="descriereaCompetitiei" placeholder="Descrierea competitiei" 
                                                name="description" onChange={validation.handleChange} 
                                                onBlur={validation.handleBlur} 
                                                value={validation.values.description}
                                                />
                                                {validation.touched.description && validation.errors.description ? (
                                                    <div className="text-danger">{validation.errors.description}</div>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="competitionLocation" className="form-label"> Locatia competitiei </Label>
                                                <Input type="text" className="form-control" id="competitionLocation" placeholder="Locatia competitiei ex. Bucuresti / National Salsa Congres" 
                                                name="location" onChange={validation.handleChange} 
                                                onBlur={validation.handleBlur} 
                                                value={validation.values.location}
                                                />
                                                {validation.touched.location && validation.errors.location ? (
                                                    <div className="text-danger">{validation.errors.location}</div>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="isParentContest" className="form-label"> Este competitia principala? </Label>
                                                <div className="form-check">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="isParentContest"
                                                        name="isParentContest"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        checked={validation.values.isParentContest}
                                                    />
                                                    <Label className="form-check-label" htmlFor="isParentContest">
                                                        Da
                                                    </Label>
                                                </div>
                                                {validation.touched.isParentContest && validation.errors.isParentContest ? (
                                                    <div className="text-danger">{validation.errors.isParentContest}</div>
                                                ) : null}
                                            </div>


                                            {!validation.values.isParentContest && (
                                                <div className="mb-3">
                                                    <Label htmlFor="parentCompetitionId" className="form-label"> Competiție Părinte </Label>
                                                    <Input
                                                        type="select"
                                                        className="form-control"
                                                        id="parentCompetitionId"
                                                        name="parentCompetitionId"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.parentCompetitionId}
                                                    >
                                                        <option value="">Selectați o competiție</option>
                                                        {parentCompetitions.map((comp: any) => (
                                                            <option key={comp._id} value={comp._id}>
                                                                {comp.name}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                    {validation.touched.parentCompetitionId && validation.errors.parentCompetitionId ? (
                                                        <div className="text-danger">{validation.errors.parentCompetitionId}</div>
                                                    ) : null}
                                                </div>
                                            )}

                                            
                                            <div className="mb-3">
                                                <Label htmlFor="date-field" className="form-label" > Data </Label>
                                                <Flatpickr
                                                name="date"
                                                className="form-control"
                                                id="date-field"
                                                placeholder="Select date"
                                                options={{
                                                mode: "single",
                                                    dateFormat: 'd M, Y',
                                                }}
                                                onChange={(date: any) => validation.setFieldValue("date", moment(date[0]).format("DD MMMM ,YYYY"))}
                                                value={validation.values.date || ''}
                                        />
                                        {validation.errors.date && validation.touched.date ? (
                                            <FormFeedback type="invalid" className='d-block'><>{validation.errors.date}</></FormFeedback>
                                        ) : null}
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="hstack gap-2 justify-content-end">
                                        <Button type="button" className="btn btn-light" onClick={toggleCreateCompetition} >
                                            Inchide
                                        </Button>
                                        <Button type="submit" className="btn btn-success">
                                            Adauga
                                        </Button>
                                        </div>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                            </React.Fragment>
                        )})}
                    </div>
                </React.Fragment>
            
            }
        </React.Fragment>
    );
}

function Loader() {
    return (
        <div className="page-content text-center">
            <l-bouncy
                size="75"
                speed="1.3" 
                color="orange" 
            />
        </div>

    )
  }

export default List;

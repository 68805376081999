
import React from 'react';
import { Input, FormGroup, Label, Button, Row, Col } from 'reactstrap';
import { Role } from 'types';

interface RoleSelectorProps {
  userId: string;
  availableRoles: Role[];
  selectedRole: Role | '';
  onChange: (userId: string, role: Role) => void;
  onAdd: (userId: string) => void;
}

const RoleSelector: React.FC<RoleSelectorProps> = ({
  userId,
  availableRoles,
  selectedRole,
  onChange,
  onAdd,
}) => {
  return (
    <Row className='g-4 align-items-center'>
            <Col sm={5}>
                <div className="col-sm">
                    <div className="d-flex">
                        <Label for={`select-role-${userId}`} className="sr-only">
                            Add Role
                        </Label>
                        <Input
                            type="select"
                            name="select"
                            id={`select-role-${userId}`}
                            value={selectedRole}
                            onChange={(e) => onChange(userId, e.target.value as Role)}
                        >
                            <option value="" disabled>
                            Select Role
                            </option>
                            {availableRoles.map((role) => (
                            <option key={role} value={role}>
                                {role.toUpperCase()}
                            </option>
                            ))}
                        </Input>
                    </div>
                </div>
            </Col>
            <div className="col-sm-auto ms-auto">
                <div className="col-sm-auto">
                    <Button
                        color="success"
                        className="mt-2"
                        onClick={() => onAdd(userId)}
                        disabled={!selectedRole}>
                        <i className="ri-add-line me-1 align-bottom"></i> Add
                    </Button>
                </div>
            </div>
    </Row>
  );
};

export default RoleSelector;
import React from 'react';
import { Badge } from 'reactstrap';
import { Role } from 'types';

interface RoleBadgesProps {
  roles: Role[];
  onRemove?: (role: Role) => void;
}

const RoleBadges: React.FC<RoleBadgesProps> = ({ roles, onRemove }) => {
  return (
    <>
      {roles.map((role) => (
        <Badge
          key={role}
          color="primary"
          className="mr-1 mb-1"
          style={{ cursor: 'pointer' }}
          onClick={() => onRemove && onRemove(role)}
        >
          {role.toUpperCase()} &times;
        </Badge>
      ))}
    </>
  );
};

export default RoleBadges;
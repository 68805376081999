import BreadCrumb from "Components/Common/BreadCrumb";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { createSelector } from "reselect";
import Section from "./Section";
import { bouncy } from 'ldrs'

//TODO: If loading is finished and no competition is found, redirect to /competitions

const CompetitionDashboard = () => {
    document.title = "Competition | Dashboard";
    bouncy.register();

    const dispatch: any = useDispatch();
    const { competitionId } = useParams();
    
    const { loading, user } = useSelector((state: any) => state.Login);

    const competitionSelector = createSelector(
        (state: any) => state.Competitions,
        (competitionState) => ({
            competitionList: competitionState.competitions
        })
    );
    const { competitionList } = useSelector(competitionSelector);

    if (loading || !user || !competitionList || competitionList.length === 0) {
      return (
          <div className="page-content">
              <Container fluid>
                <div className="page-content text-center">
                    <l-bouncy
                        size="75"
                        speed="1.3" 
                        color="orange" 
                    />
                </div>
              </Container>
          </div>
      );
  }


    const competition = competitionList.find((comp: any) => comp._id === competitionId);

    return (
      <React.Fragment>
        <div className="page-content">
            <Container fluid>                    
                <Section competition={competition}/>
            </Container>
        </div>
     </React.Fragment>
    );
};

export default CompetitionDashboard;
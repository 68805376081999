import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import { createSelector } from "reselect";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
import { Link, useParams } from "react-router-dom";



const CompetitionListTable = ({competition}: any) => {
    const dispatch: any = useDispatch();

    const selectCompetitionState = (state: any) => state.Competitions;
    const competitionSelector =  useMemo(() => createSelector(
        selectCompetitionState,
        (competitionState) => ({
            competitionsList: competitionState.competitions,
            isLoadingCompetitions: competitionState.loading,
            competitionError: competitionState.error
        })
    ), []);



    const { competitionsList } = useSelector(competitionSelector);

    const childCompetitions = useMemo(() => {
        if(competition.isParentContest){
            return competitionsList.filter((comp: any) => comp.parentContest === competition._id);
        }
        return [];
    }, [competitionsList, competition]);



    return (
        <>
        {childCompetitions.map((competition: any) => (
            <Link to={`/competition/${competition._id}`}>
                <Card className="joblist-card">
                    <CardBody className="card-body">
                            <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                                <div className="flex-shrink-0">
                                    <div className="avatar-sm rounded">
                                        <div className="avatar-title border bg-light text-primary rounded text-uppercase fs-16">
                                            {competition.name.charAt(0)}
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-3">
                                    <h5 className="fs-16-mb-2">{competition.name}</h5>
                                </div>
                                <div className="ms-auto">
                                    <div className="counter-value">
                                        <CountUp
                                                    start={0}
                                                    end={competition.participants.length}
                                                    decimals={0}
                                                    duration={1}
                                            />
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title bg-info-subtle rounded-circle fs-2">
                                            <FeatherIcon
                                                icon="users"
                                                className="text-info"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Link>
                ))}
        </>
    );
}

export default CompetitionListTable;
import { bouncy } from "ldrs";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Input, Table, Card, CardBody } from "reactstrap";
import CreateDanceSchool from "./CreateDanceSchool";
import CreateDanceSchoolModal from "./CreateDanceSchoolModal";
import Pagination from "Components/Common/Pagination";
import { createSelector } from "reselect";
import { toast } from "react-toastify";


const DanceSchoolDashboard: React.FC = () => {
    // Set document title and register bouncy animation
    useEffect(() => {
      document.title = "Dance School | Dashboard";
      bouncy.register();
    }, []);
  
    const dispatch: any = useDispatch();
  
    // Selectors
  
    const selectUserState = (state: any) => state.Users;

    const userSelector = createSelector(
        selectUserState,
        (userState) => ({
          userList: userState.userList,
          isLoadingUsers: userState.loading,
          userError: userState.error,
        })
      );

    const { userList, isLoadingUsers, userError } = useSelector(userSelector);

    const selectDanceSchoolState = (state: any) => state.DanceSchool;

    const danceSchoolSelector = createSelector(
        selectDanceSchoolState,
        (danceSchoolState) => ({
            danceSchoolList: danceSchoolState.danceSchoolList,
            isLoadingDanceSchool: danceSchoolState.loading,
            danceSchoolError: danceSchoolState.error,
        })
    );

    const { danceSchoolList, isLoadingDanceSchool, danceSchoolError } = useSelector(danceSchoolSelector);


    // Local state
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
  
    const [isDanceSchoolModalOpen, setIsDanceSchoolModalOpen] = useState<boolean>(false);

      // Toggle function for DanceSchoolModal
  const toggleDanceSchoolModal = useCallback(() => {
        setIsDanceSchoolModalOpen((prev) => !prev);
    }, []);
    // Constants
    const PER_PAGE = 10;
  

  
    const handleSearch = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
      },
      []
    );
  
    // Fetch dance schools on component mount ? already getting them from redux
    // useEffect(() => {
    //   dispatch(fetchDanceSchools());
    // }, [dispatch]);
  
  // Create a map of user IDs to user details
    const userMap = useMemo(() => {
        const map: Record<string, { name: string; surname: string }> = {};
        userList.forEach((user: any) => {
        map[user._id] = { name: user.name, surname: user.surname };
        });
        return map;
    }, [userList]);

    console.log(userMap);

    // Map danceSchoolList to include owner names
    const danceSchoolsWithOwners = useMemo(() => {
        return danceSchoolList.map((school: any) => {
        const owners = school.owner.map((ownerId: any) => {
            const owner = userMap[ownerId];
            if (owner) {
            return `${owner.name} ${owner.surname}`;
            } else {
            return "Unknown Owner";
            }
        });
        return {
            ...school,
            ownerNames: owners.join(", "),
        };
        });
    }, [danceSchoolList, userMap]);

    // console.log(danceSchoolsWithOwners);


    // Filtered dance schools based on search term
    const filteredDanceSchools = useMemo(() => {
        if (!searchTerm) return danceSchoolsWithOwners;
        return danceSchoolsWithOwners.filter((school: any) =>
        Object.values(school).some(
            (field) =>
            typeof field === "string" &&
            field.toLowerCase().includes(searchTerm.toLowerCase())
        )
        );
    }, [danceSchoolsWithOwners, searchTerm]);
  
    // Pagination calculations
    const indexOfLast = currentPage * PER_PAGE;
    const indexOfFirst = indexOfLast - PER_PAGE;
    const currentDanceSchools = useMemo(
      () => filteredDanceSchools.slice(indexOfFirst, indexOfLast),
      [filteredDanceSchools, indexOfFirst, indexOfLast]
    );
  
    // Handle loading and empty states
    if (isLoadingUsers || isLoadingDanceSchool || !userList) {
      return (
        <div className="page-content text-center">
          <Container fluid>
            <l-bouncy size="75" speed="1.3" color="orange" />
          </Container>
        </div>
      );
    }
  
    if (!isLoadingDanceSchool && danceSchoolList.length === 0) {
      console.log("No dance schools found");
      return (
        <div className="page-content">
          <CreateDanceSchool />
        </div>
      );
    }
  
    return (
      <div className="page-content">
        <Container fluid>
          {/* Header Row with Add and Search */}
          <Row className="g-4 mb-4">
            <Col sm="auto">
              <Button color="primary" onClick={toggleDanceSchoolModal}>
                <i className="ri-add-line align-bottom me-1"></i> Adaugă Școală de Dans
              </Button>
            </Col>
            <Col sm="3" className="ms-auto">
              <Input
                type="text"
                placeholder="Caută școală de dans..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </Col>
          </Row>
  
        {/* Dance School Modal */}
        <CreateDanceSchoolModal isOpen={isDanceSchoolModalOpen} toggle={toggleDanceSchoolModal} />

  
          {/* Dance School Table */}
            {isLoadingDanceSchool ? (
                <div className="text-center w-100">
                <l-bouncy size="75" speed="1.3" color="orange" />
              </div>
            ) : (
            <Row className="gy-2 mb-2" id="dance-school-list">
                {currentDanceSchools.length === 0 ? (
                    <div className="text-center page-content">
                        <div className="row">
                            <Row><h5>Lista Școlilor de Dans</h5></Row>
                        </div>
                        <p>Nu există școli de dans</p>
                    </div>
                ) : (
                  currentDanceSchools.map((school: any, index: number) => (
                    <Col className="col-lg-12 card-animate" key={school._id} onClick={() => {
                      toast.success('Clicked on school: ' + school.name);
                      // navigate(`/dance-school/${school._id}`);
                    }}>
                        <Card className="card mb-0">
                            <CardBody className="card-body">
                                <div className="d-lg-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <div className="avatar-sm rounded">
                                            <div className="avatar-title border bg-light text-primary rounded text-uppercase fs-16">
                                                {school.name.charAt(0)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <Link to={`/dance-school/${school._id}`}>
                                            <h5 className="fs-16-mb-2">{school.name}</h5>
                                        </Link>
                                            <p className="text-muted">{school.ownerNames}</p>
                                    </div>
                                    <div className="d-flex gap-4 mt-0 text-muted mx-auto">
                                        <div>
                                            <i className="ri-map-pin-2-line text-primary me-1 align-bottom"></i>{" "}
                                            {school.address}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap gap-2 align-items-center mx-auto">
                                        <div className="text-muted d-inline-block">
                                            <i className="ri-phone-line fs-16"></i>
                                            {school.phoneNumber}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )))}
            </Row>
            )}
  
        {/* Pagination */}
        {filteredDanceSchools.length > PER_PAGE && (
          <Row>
            <Col>
              <Pagination
                perPageData={PER_PAGE}
                data={filteredDanceSchools}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default DanceSchoolDashboard;
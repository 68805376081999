import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";



export interface DanceSchoolState {
    loading: boolean;
    danceSchoolList: any[];
    error: string | null;
}

export const initialState: DanceSchoolState = {
    loading: false,
    danceSchoolList: [],
    error: null,
}

const DanceSchoolSlice = createSlice({
    name: 'DanceSchool',
    initialState,
    reducers: {
        fetchDanceSchoolsRequest(state) {
            state.loading = true;
            state.error = null;
        },
        fetchDanceSchoolsSuccess(state, action: PayloadAction<any[]>) {
            console.log('fetchDanceSchoolsSuccess', action.payload);
            state.loading = false;
            state.danceSchoolList = action.payload;  // Spread the current state and add new items
        },
        fetchDanceSchoolsError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },

        updateDanceSchoolRequest(state) {
            state.loading = true;
            state.error = null;
        },
        updateDanceSchoolSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.danceSchoolList = state.danceSchoolList.map((danceSchool) => danceSchool.id === action.payload.id ? action.payload : danceSchool);
        },
        updateDanceSchoolError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },

        createDanceSchoolError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        createDanceSchoolSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.danceSchoolList = [...state.danceSchoolList, action.payload];
        }
    }
});

export const {
    fetchDanceSchoolsRequest,
    fetchDanceSchoolsSuccess,
    fetchDanceSchoolsError,
    updateDanceSchoolRequest,
    updateDanceSchoolSuccess,
    updateDanceSchoolError,
    createDanceSchoolError,
    createDanceSchoolSuccess
} = DanceSchoolSlice.actions;

export default DanceSchoolSlice.reducer;

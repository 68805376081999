import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";
import UsersReducer from './users/reducer';
import CompetitionsReducer from './competitions/reducer';
import DanceSchoolReducer from './dance-school/reducer';
import SongsReducer from './songs/reducer';

const appReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Users: UsersReducer,
    Competitions: CompetitionsReducer,
    DanceSchool: DanceSchoolReducer,
    Songs: SongsReducer,
});

export default appReducer;
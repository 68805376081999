import { useFormik } from "formik";
import { addSongsMultiple } from "helpers/backend_helper";
import React from "react";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Alert, Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from "reactstrap";
import { createSelector } from "reselect";
import { DanceType, UploadSongPayload } from "types";
import * as Yup from 'yup';
import Select from 'react-select';


const danceTypeOptions = [
    { value: 'salsa', label: 'Salsa' },
    { value: 'bachata', label: 'Bachata' },
];


const SongUploader = () => {
    const dispatch: any = useDispatch();


    const selectSongsState = (state: any) => state.Songs;

    const songsSelector = createSelector(
        selectSongsState,
        (songsState: any) => ({
            songs: songsState.songs,
            isLoadingSongs: songsState.loading,
            songsError: songsState.error,
        })
    );

    const { songs, isLoadingSongs, songsError } = useSelector(songsSelector);

    const selectCompetitionState = (state: any) => state.Competitions;
    const competitionsSelector = createSelector(
        selectCompetitionState,
        (competitionsState: any) => ({
            competitions: competitionsState.competitions,
            isLoadingCompetitions: competitionsState.loading,
            competitionsError: competitionsState.error,
        })
    );


    // State for managing selected songs
    const [selectedSongIndices, setSelectedSongIndices] = useState<number[]>([]);

    // State for bulk dance type selection
    const [bulkDanceType, setBulkDanceType] = useState<DanceType | null>(null);

    const { competitions, isLoadingCompetitions, competitionsError } = useSelector(competitionsSelector);

    const [files, setFiles] = useState<File[]>([]);

    const onDrop = (acceptedFiles: File[]) => {
        // Validate file types and sizes
        const validFiles = acceptedFiles.filter(file => {
            const isValidType = ['audio/mpeg', 'audio/wav', 'audio/mp3'].includes(file.type);
            const isValidSize = file.size <= 10 * 1024 * 1024; // 10MB limit
            if (!isValidType) {
                toast.error(`${file.name} is not a supported audio format.`);
            }
            if (!isValidSize) {
                toast.error(`${file.name} exceeds the 10MB size limit.`);
            }
            return isValidType && isValidSize;
        });

        // Prevent duplicate files
        const uniqueFiles = validFiles.filter(file => !files.some(existingFile => existingFile.name === file.name));
        if (uniqueFiles.length !== validFiles.length) {
            toast.warning('Some files were already selected and have been skipped.');
        }

        setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);

    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    const formik = useFormik({
        initialValues: {
            songs: [] as {
                name: string;
                type: DanceType;
                location: string;
            }[],
            competitionId: null as { value: string; label: string } | null
        },
        validationSchema: Yup.object().shape({
            songs: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required('Name is required'),
                    type: Yup.string().oneOf(['salsa', 'bachata']).required('Type is required'),
                    location: Yup.string().required('Location is required'),
                })
            ).min(1, 'At least one song must be selected'),
        }),
        onSubmit: async (values, { resetForm }) => {
            // Prepare the payload
            const payload: UploadSongPayload = {
                songs: values.songs.map(song => ({
                    name: song.name,
                    type: song.type,
                    location: song.location, // Since no upload, location can be the file name or another identifier
                })),
                competitionId: values.competitionId ? values.competitionId.value : null,
            };

            try {
                dispatch(addSongsMultiple(payload));
                resetForm();
                setFiles([]);
                setSelectedSongIndices([]);
                setBulkDanceType(null);
            } catch (err: any) {
                toast.error(err || 'Failed to save songs.');
            }
        },
    });

    // Handle type selection for each file
    const handleTypeChange = (index: number, selectedOption: any) => {
        const updatedSongs = [...formik.values.songs];
        updatedSongs[index].type = selectedOption.value;
        formik.setFieldValue('songs', updatedSongs);
    };

    // Handle removing a file
    const handleRemoveFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);

        const updatedSongs = [...formik.values.songs];
        updatedSongs.splice(index, 1);
        formik.setFieldValue('songs', updatedSongs);
    };
    
    // Select/Deselect All Songs
    const handleSelectAll = () => {
        if (selectedSongIndices.length === formik.values.songs.length) {
            setSelectedSongIndices([]);
        } else {
            const allIndices = formik.values.songs.map((_, idx) => idx);
            setSelectedSongIndices(allIndices);
        }
    };

    // Select/Deselect Individual Song
    const handleSelectSong = (index: number) => {
        if (selectedSongIndices.includes(index)) {
            setSelectedSongIndices(selectedSongIndices.filter(i => i !== index));
        } else {
            setSelectedSongIndices([...selectedSongIndices, index]);
        }
    };

    const handleBulkChange = () => {
        if (!bulkDanceType) return;
        
        const updatedSongs = [...formik.values.songs];
        selectedSongIndices.forEach(index => {
            updatedSongs[index].type = bulkDanceType;
        });
        formik.setFieldValue('songs', updatedSongs);
        
        toast.success(`Dance Type changed to ${bulkDanceType} for selected songs.`);
    };

    // Update Formik songs when files change
    useEffect(() => {
        const songEntries = files.map(file => ({
            name: file.name,
            type: 'salsa' as DanceType, // Default type
            location: file.name, // Since no upload, location can be the file name or another identifier
        }));
        formik.setFieldValue('songs', songEntries);
    }, [files]);


    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                <Card>
                                    
                                    <CardBody>
                                        <div {...getRootProps()} className="dropzone dz-clickable mb-3">
                                            <div className="dz-message needsclick"  {...getRootProps()}>
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                                    <input {...getInputProps()} aria-label="File Upload Input" />

                                                </div>
                                                {isDragActive ? <h4>Drop files here or click to upload.</h4> : <h4>Drop files here or click to upload.</h4>}
                                            </div>
                                        </div>
                                        <div className="list-unstyled mb-0">
                                            <FormGroup>
                                                <div className="mb-1">
                                                    <Label for="competition">Assign to Competition (Optional)</Label>
                                                </div>
                                                <Select
                                                    id="competition"
                                                    name="competition"
                                                    options={competitions.map((comp: any) => ({ value: comp._id, label: comp.name }))}
                                                    value={formik.values.competitionId}
                                                    onChange={(selectedOption: any) => formik.setFieldValue('competitionId', selectedOption)}
                                                    isClearable
                                                    placeholder="Alege o competitie..."
                                                    />
                                            </FormGroup>
                                            <Form onSubmit={formik.handleSubmit}>
                                                {/* Bulk Change Controls */}
                                                {formik.values.songs.length > 0 && (
                                                    <FormGroup className="d-flex align-items-center mb-3">
                                                        <Label for="bulkDanceType" className="me-2 mb-0">Bulk Change Dance Type:</Label>
                                                        <Select
                                                            id="bulkDanceType"
                                                            name="bulkDanceType"
                                                            options={danceTypeOptions}
                                                            value={danceTypeOptions.find(option => option.value === bulkDanceType)}
                                                            onChange={(selectedOption: any) => setBulkDanceType(selectedOption ? selectedOption.value : null)}
                                                            isClearable
                                                            placeholder="Select Dance Type..."
                                                            className="me-2"
                                                            style={{ width: '200px' }}
                                                        />
                                                        <Button
                                                            color="primary"
                                                            onClick={handleBulkChange}
                                                            disabled={selectedSongIndices.length === 0 || !bulkDanceType}
                                                        >
                                                            Apply to Selected
                                                        </Button>
                                                    </FormGroup>
                                                )}



                                                {formik.values.songs.length > 0 && (
                                                    <Table bordered className="mt-4">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={handleSelectAll}
                                                                        checked={selectedSongIndices.length === formik.values.songs.length && formik.values.songs.length > 0}
                                                                        aria-label="Select All Songs"
                                                                    />
                                                                </th>
                                                                <th>#</th>
                                                                <th>File Name</th>
                                                                <th>Dance Type</th>
                                                                <th>Location</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {formik.values.songs.map((song, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedSongIndices.includes(index)}
                                                                            onChange={() => handleSelectSong(index)}
                                                                            aria-label={`Select song ${index + 1}`}
                                                                        />
                                                                    </td>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{song.name}</td>
                                                                    <td style={{ width: '200px' }}>
                                                                        <Select
                                                                            options={danceTypeOptions}
                                                                            value={danceTypeOptions.find(option => option.value === song.type)}
                                                                            onChange={(option: any) => handleTypeChange(index, option)}
                                                                        />
                                                                    </td>
                                                                    <td>{song.location}</td>
                                                                    <td>
                                                                        <Button color="danger" size="sm" onClick={() => handleRemoveFile(index)}>Remove</Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                )}
                                                {songsError && <Alert color="danger">{songsError}</Alert>}
                                                <div className="d-flex justify-content-end">
                                                    
                                                    <Button color="success" type="submit" disabled={isLoadingSongs || formik.values.songs.length === 0}>
                                                        {isLoadingSongs ? 'Saving...' : 'Save Songs'}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>                
                </Col>
            </Row>
            
        </React.Fragment>
    )
}

export default SongUploader;
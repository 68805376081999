// src/components/Modals/DanceSchoolModal.tsx

import React from "react";
import {
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Input,
  Label,
  Button,
  ModalFooter,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createDanceSchool } from "slices/thunks"; // Adjust the import path as necessary
import UserDropdown from "./UserDropdown";

// Define TypeScript types for props
interface CreateDanceSchoolModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const CreateDanceSchoolModal: React.FC<CreateDanceSchoolModalProps> = ({ isOpen, toggle }) => {
  const dispatch: any = useDispatch();

  const validation = useFormik({
    initialValues: {
      owner: {
        email: "",
        name: "",
        surname: "",
        phoneNumber: "",
        isCreating: false,
      },
      name: "",
      description: "",
      location: "",
      phoneNumber: "",
      businessType: "dance_school",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Numele este obligatoriu"),
      owner: Yup.object().shape({
        email: Yup.string()
          .email("Email invalid")
          .when("isCreating", {
            is: true,
            then: (schema) => schema.required("Email este obligatoriu"),
            otherwise: (schema) => schema.notRequired(),
          }),
        name: Yup.string().when("isCreating", {
          is: true,
          then: (schema) => schema.required("Prenumele este obligatoriu"),
          otherwise: (schema) => schema.notRequired(),
        }),
        surname: Yup.string().when("isCreating", {
          is: true,
          then: (schema) =>
            schema.required("Numele de familie este obligatoriu"),
          otherwise: (schema) => schema.notRequired(),
        }),
        phoneNumber: Yup.string().min(7).when("isCreating", {
          is: true,
          then: (schema) =>
            schema.required("Numarul de telefon este obligatoriu"),
          otherwise: (schema) => schema.notRequired(),
        }),
        isCreating: Yup.boolean(),
      }),
    }),
    onSubmit: (values) => {
      dispatch(createDanceSchool(values));
      validation.resetForm();
      toggle();
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      backdrop="static"
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Adaugă Școală de Dans
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <Input type="hidden" id="id-field" />
          <div className="mb-3 d-none" id="modal-id">
            <Label className="form-label">Școală</Label>
            <Input
              type="text"
              className="form-control"
              id="scoala"
              placeholder="Școală"
              readOnly
            />
          </div>
          <div className="text-center">
            <div className="position-relative d-inline-block">
              <div className="position-absolute bottom-0 end-0"></div>
            </div>
          </div>
          <div className="mb-3 form-floating">
            <Input
              type="text"
              className="form-control"
              id="numeleScolii"
              placeholder="Numele școlii de dans ex. iDance"
              name="name"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name}
            />
            {validation.touched.name && validation.errors.name ? (
              <div className="text-danger">{validation.errors.name}</div>
            ) : null}
            <Label htmlFor="numeleScolii" className="form-label">
              Numele școlii
            </Label>
          </div>

          <div className="mb-3">
            <UserDropdown
              value={validation.values.owner}
              onBlur={() => validation.setFieldTouched("owner", true)}
              onChange={(value) => {
                if (value.isCreating) {
                  validation.setFieldValue("owner", value);
                  validation.setFieldTouched("owner", true);
                } else {
                  validation.setFieldValue("owner", value);
                  validation.setFieldTouched("owner", false);
                }
              }}
              errors={validation.errors.owner}
              touched={validation.touched.owner}
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="descriereaScolii" className="form-label">
              Descrierea școlii
            </Label>
            <Input
              type="text"
              className="form-control"
              id="descriereaScolii"
              placeholder="Descrierea școlii ex. Salsa / Bachata / etc."
              name="description"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description}
            />
            {validation.touched.description && validation.errors.description ? (
              <div className="text-danger">
                {validation.errors.description}
              </div>
            ) : null}
          </div>

          <div className="mb-3">
            <Label htmlFor="location" className="form-label">
              Adresa școlii
            </Label>
            <Input
              type="text"
              className="form-control"
              id="location"
              placeholder="Strada Traian Popovici, București"
              name="location"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.location}
            />
            {validation.touched.location && validation.errors.location ? (
              <div className="text-danger">{validation.errors.location}</div>
            ) : null}
          </div>

          <div className="mb-3">
            <Label htmlFor="phoneNumber" className="form-label">
              Numar de telefon
            </Label>
            <Input
              type="text"
              className="form-control"
              id="phoneNumber"
              placeholder="0723456789"
              name="phoneNumber"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.phoneNumber}
            />
            {validation.touched.phoneNumber &&
            validation.errors.phoneNumber ? (
              <div className="text-danger">
                {validation.errors.phoneNumber}
              </div>
            ) : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" onClick={toggle}>
              Închide
            </Button>
            <Button type="submit" className="btn btn-success">
              Adaugă
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateDanceSchoolModal;
import {  fetchUsersError, fetchUsersSuccess } from "slices/users/reducer";

import {
  getCompetitions,
    getUsersList as getUsersListApi,
    getDanceSchools as getDanceSchoolsApi
} from "../../helpers/backend_helper"
import { fetchCompetitionsFailure, fetchCompetitionsSuccess } from "slices/competitions/reducer";
import { fetchDanceSchoolsSuccess, fetchDanceSchoolsError } from "slices/dance-school/reducer";
import { getSongs as getSongsApi } from "helpers/backend_helper";
import { fetchSongsError, fetchSongsSuccess } from "slices/songs/reducer";


export const fetchInitialData = () => async (dispatch: any) => {
    try {
      // Fetch users and update the store
      const usersResponse = await getUsersListApi();
      dispatch(fetchUsersSuccess(usersResponse.users));
      
      // Add other API calls and dispatch actions to update the state
      // e.g. competitions, user profile, etc.
    } catch (error: any) {
      dispatch(fetchUsersError(error));
      console.error("Error fetching data for authenticated user", error);
    }

    try {
      const competitionsResponse:any = await getCompetitions();

      if (Array.isArray(competitionsResponse.competitions)) {
        dispatch(fetchCompetitionsSuccess(competitionsResponse.competitions));
      } else {
        throw new Error("Failed to fetch competitions");
      }    
    } catch (error: any) {
      dispatch(fetchCompetitionsFailure(error));
      console.error("Error fetching competitions", error);
    }

    try {
      const usersResponse:any = await getUsersListApi();
      dispatch(fetchUsersSuccess(usersResponse.users));
    } catch (error: any) {
      dispatch(fetchUsersError(error.message));
    }

    try {
      const danceSchools = {
        type: "dance_school"
      }
      const danceSchoolsResponse:any = await getDanceSchoolsApi(danceSchools);
      dispatch(fetchDanceSchoolsSuccess(danceSchoolsResponse));
    } catch (error: any) {
      dispatch(fetchDanceSchoolsError(error.message));
    }

    try {
      const songsResponse:any = await getSongsApi();
      dispatch(fetchSongsSuccess(songsResponse));
    } catch (error: any) {
      dispatch(fetchSongsError(error.message));
    }
  };


import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getToken, getMessaging } from "firebase/messaging";
import { logoutUser } from 'slices/thunks';
import * as firebaseInstance from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { loginSuccess, logoutUserSuccess } from 'slices/auth/login/reducer';
import { saveDeviceToken } from 'helpers/backend_helper';
import { browserSessionPersistence } from "firebase/auth";
import { error } from 'console';
import { profileSuccess } from 'slices/auth/profile/reducer';


class FirebaseAuthBackend {
  
  constructor(firebaseConfig: any, dispatch?: Function) {
    console.log('Initializing FirebaseAuthBackend');
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.setLogLevel('verbose');
      getAnalytics(firebase.app());
      firebase.auth().onAuthStateChanged(async (user: firebase.User | null) => {
        if (user) {
          // Dispatch login success to update Redux state
          const serializedUser =  await serializeFirebaseUser(user);
          if(dispatch) {
            console.log('Fac dispatch loginSuccess din FirebasAuthBackend.tsx');
            console.log('dispatch', dispatch);
            dispatch(loginSuccess(serializedUser));
            dispatch(profileSuccess(serializedUser));
          }
        } else {
          // Dispatch logout action to update Redux state
          // console.log('Calling log-out user from FirebaseBackEnd');
          if(dispatch) dispatch(logoutUserSuccess(true));
        }
      });
    }
  }

  registerUser = (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  geMessagingToken = async () => {
    let messagingToken = localStorage.getItem('messagingToken') ?? null;
    try {
          if(!messagingToken) {
            console.log('No messaging token checking service worker');
            if('serviceWorker' in navigator) {
              navigator.serviceWorker.ready.then(async registrations => {
                  if(registrations.active?.state === 'activated' && registrations.active.scriptURL === window.location.origin + '/firebase-messaging-sw.js') {
                    if(Notification.permission === 'granted') {
                      messagingToken = await getToken(getMessaging(firebaseInstance.getApp()), {vapidKey: process.env.REACT_VAPPID, serviceWorkerRegistration: registrations});
                      localStorage.setItem("messagingToken", messagingToken);
                      await saveDeviceToken(messagingToken);
                      return messagingToken;
                    } else {
                      return null;
                    }
                  }
              })
            } else {
              // console.log('No service worker registering service worker');
            }
          } else {
            if(Notification.permission === 'granted') {

              await navigator.serviceWorker.getRegistrations().then(registrations => {
                registrations.forEach(registration => {
                    registration.update();
                });
              });

              // await getToken(getMessaging(firebaseInstance.getApp()), {vapidKey: process.env.REACT_VAPPID});
              // await saveDeviceToken(messagingToken); - commented this messaging token should only be sent 
              return messagingToken;
            } else {
              return null;
            }
          }
      
    } catch (error) {
      console.log('Error while getting push token : ', error)
      return null
    }
  }


  editProfileAPI = (username: any, idx: any) => {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        // Update the display name        
        currentUser.updateProfile({
          displayName: username
        })
          .then(() => {
            const data = (currentUser as any).multiFactor.user.displayName;

            resolve({ data: { username: data }, status: "Profile Updated Successfully" });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error('User not authenticated'));
      }
    });
  }

  loginUser = (email: any, password: any, authRememberCheck: boolean) => {
    //TODO: de salvat in localStorage daca e pe sesiune sau e cu retinere
    //FIXME: for now setting this to ALWAY TRUE 
    authRememberCheck = true;
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .setPersistence(authRememberCheck ? "local" : "session")
        .then(() => {
          return firebase
                  .auth()
                  .signInWithEmailAndPassword(email, password)
                  .then(async (userCredential) => {

                    const user = userCredential.user;
                    if (user) {
                      const serializedUser = await serializeFirebaseUser(user);
                      resolve(serializedUser);  // Return serialized user with token
                    }
                  })
                  .catch(error => {
                    reject(this._handleError(error));
                  })
        });
    });
  };

  forgetPassword = (email: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  logout = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  socialLoginUser = async (type: any) => {
    let provider: firebase.auth.AuthProvider | null = null;
    if (type === "google") {
      provider = new firebase.auth.GoogleAuthProvider();
    } else if (type === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    if (provider) {
      try {
        const result = await firebase.auth().signInWithPopup(provider);
        const user = result.user;
        return user;
      } catch (error : any) {
        throw this._handleError(error);
      }
    }
    throw new Error(`Invalid social login type: ${type}`);
  };

  addNewUserToFirestore = (user: any) => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo!;
    const details = {
      firstName: profile?.given_name ? profile.given_name : profile?.first_name,
      lastName: profile?.family_name ? profile.family_name : profile?.last_name,
      fullName: profile?.name,
      email: profile?.email,
      picture: profile?.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    collection.doc(firebase.auth().currentUser!.uid).set(details);
    return { user, details };
  };

  getAuthenticatedUser = async (): Promise<any | null> => {

    const authUser = await firebase.app().auth().currentUser;
    if (!authUser) return null;
    const serializedUser = await serializeFirebaseUser(authUser);
    return serializedUser;
  };

  _handleError(error: firebase.auth.Error): string {
    return error.message;
  }
}

let _fireBaseBackend: FirebaseAuthBackend | null = null;

const initFirebaseBackend = (config: any, dispatch?: any): FirebaseAuthBackend => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config, dispatch);
  }
  return _fireBaseBackend;
};


const getFirebaseBackend = (): FirebaseAuthBackend | null => {
  return _fireBaseBackend;
};

const serializeFirebaseUser = async (user: firebase.User | null) => {
  if (!user) return null;
  
  const token = await user.getIdToken();  // Get the Firebase ID token
  const customClaims = await user.getIdTokenResult();
  const serializedUser = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    emailVerified: user.emailVerified,
    phoneNumber: user.phoneNumber,
    providerId: user.providerId,
    roles: customClaims.claims.roles ? customClaims.claims.roles : [],
    metadata: {
      creationTime: user.metadata.creationTime,
      lastSignInTime: user.metadata.lastSignInTime,
    },
    token,  // Add the JWT token to the serialized user object
  };
  
  return serializedUser; 
};

export { initFirebaseBackend, getFirebaseBackend, serializeFirebaseUser };

import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListGroup, ListGroupItem, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { createSelector } from "reselect";
import { Chip } from 'primereact/chip';
import { toast } from "react-toastify";
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { removeUserFromCompetition } from "slices/thunks";

interface CompetitorDetailsProps {
    competitorId: any;
    isOpen: boolean;
    toggle: () => void;
}


const CompetitorDetails = ({ competitorId, isOpen, toggle }: CompetitorDetailsProps) => {
    const selectCompetitionState = (state: any) => state.Competitions;
    const dispatch:any = useDispatch();
    const userSelector = useMemo(() => {
        return (state: any) => state.Users.userList.find((user:any) => user._id === competitorId._id);
    }, [competitorId]);

    const competitionsSelector = useMemo(() => {
        return createSelector(
            selectCompetitionState,
            (competitionState) => ({
                competitions: competitionState.competitions.filter((c: any) => c.participants.some((participant: any) => participant._id === competitorId._id)),
            })
        )
    }, [competitorId])

        // // Selector to get all competitions
        // const competitionsSelector = useMemo(() => {
        //     return (state: any) => state.Competitions.competitions;
        // }, []);

    const  competitions: any = useSelector(competitionsSelector);
    const user: any | undefined = useSelector(userSelector);
    console.log(user)
    console.log(competitions)

        // Handle case where user is not found
        if (!user) {
            // Optionally, you can show a loading state or a message within the Offcanvas
            return (
                <Offcanvas isOpen={isOpen} toggle={toggle} direction="end">
                    <OffcanvasHeader toggle={toggle}>Competitor Details</OffcanvasHeader>
                    <OffcanvasBody>
                        <p>Loading...</p>
                    </OffcanvasBody>
                </Offcanvas>
            );
        }

        const handleRemoveCompetition = (competitionId: string) => {
            dispatch(removeUserFromCompetition(competitionId, competitorId._id));

            //TODO: Remove competition from user
            //TODO: dispatch action to update user
        }

        return (
            <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" style={{ minWidth: "70vh" }}>
                <OffcanvasHeader toggle={toggle}>Competitor Details</OffcanvasHeader>
                <OffcanvasBody>
                    <h5>Name:</h5>
                    <p>{user.name} {user.surname}</p>
                    <h5>Email:</h5>
                    <p>{user.email ? user.email : "None"}</p>
                    <h5>Telefon:</h5>
                    <p>{user.phoneNumber ? user.phoneNumber : "None"}</p>
                    <h5>Competitions Assigned:</h5>
                    {competitions.competitions.length > 0 ? (
                            <div className="hstack gap-3 flex flex-wrap">
                            {competitions.competitions.map((compName: any, index: any) => (
                                    <Chip color="primary" label={compName.name} removable onRemove={() => handleRemoveCompetition(compName._id)}></Chip>
                            ))}
                            </div>
                    ) : (
                        <p>None</p>
                    )}
                    {/* Add more user details here as needed */}
                </OffcanvasBody>
            </Offcanvas>
        );
};

export default CompetitorDetails;
import React, { useState } from "react"
import { Row, Col, Card, CardBody, Modal, ModalHeader, Form, ModalBody, Input, Label, FormFeedback, ModalFooter, Button } from "reactstrap"
import vertication from "../../../../assets/images/verification-img.png";
import * as Yup from "yup";
import { useFormik } from "formik";

import moment from "moment";
import { useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";
import { createCompetition } from "slices/thunks";


const CreateCompetition = () => {

    const [isCreateCompetition, setIsCreateCompetition] = useState<boolean>(false);
    const toggleCreateCompetition = () => setIsCreateCompetition(!isCreateCompetition);

    const dispatch: any = useDispatch();

    const validation = useFormik({
        initialValues: {
            name: "",
            description: "",
            location: "",
            date: new Date(),
            isParentContest: true,
        },
        validationSchema: Yup.object().shape({  
            name: Yup.string().required("Numele este obligatoriu"),
            description: Yup.string().required("Descrierea este obligatorie"),
            location: Yup.string().required("Locatia este obligatorie"),
            date: Yup.date().required("Ziua este obligatorie"),
            isParentContest: Yup.boolean().required("Tipul de competitie este obligatoriu"),
        }),
        onSubmit: (values) => {
            console.log(values);
            dispatch(createCompetition(values));
            validation.resetForm();
            toggleCreateCompetition();
        }
    });
    

    return (
        <React.Fragment>
            <Row className="justify-content-center">
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <div className="text-center">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <h4 className="mt-4 fw-semibold">Competitii</h4>
                                        <p className="text-muted mt-3">
                                        Nu exista nici o competitie creata. Incepe procesul de creare a unei competitii.{" "}
                                        </p>
                                        <div className="mt-4">
                                        <button
                                            type="button"
                                            onClick={toggleCreateCompetition}
                                            className="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                        >
                                            Start
                                        </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center mt-5 mb-2">
                                <Col sm={7} xs={8}>
                                    <img src={vertication} alt="" className="img-fluid" />
                                </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal
                isOpen={isCreateCompetition}
                toggle={toggleCreateCompetition}
                centered={true}
                size="lg">
                <ModalHeader className="bg-light p-3" toggle={toggleCreateCompetition}>
                    Adauga competitie
                </ModalHeader>
                <Form className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                >
                    <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <div className="mb-3 d-none" id="modal-id">
                            <Label type="text" className="form-label">Competitie</Label>
                            <Input type="text" className="form-control" id="competitionId" placeholder="Competition" readOnly />
                        </div>

                        <div className="text-center">
                            <div className="position-relative d-inline-block">
                                <div className="position-absolute bottom-0 end-0">
                                    
                                </div>
                            </div>
                        </div>

                            <div className="mb-3">
                                <Label htmlFor="numeleCompetitiei" className="form-label"> Numele competitiei </Label>
                                <Input type="text" className="form-control" id="numeleCompetitiei" placeholder="Numele competitiei" 
                                name="name" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.name}
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <div className="text-danger">{validation.errors.name}</div>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="descriereaCompetitiei" className="form-label"> Descrierea competitiei </Label>
                                <Input type="text" className="form-control" id="descriereaCompetitiei" placeholder="Descrierea competitiei" 
                                name="description" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.description}
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <div className="text-danger">{validation.errors.description}</div>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="competitionLocation" className="form-label"> Locatia competitiei </Label>
                                <Input type="text" className="form-control" id="competitionLocation" placeholder="Locatia competitiei ex. Bucuresti / National Salsa Congres" 
                                name="location" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.location}
                                />
                                {validation.touched.location && validation.errors.location ? (
                                    <div className="text-danger">{validation.errors.location}</div>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label htmlFor="isParentContest" className="form-label"> Este competitia principala? </Label>
                                <Input type="checkbox" className="form-control" id="isParentContest" 
                                name="isParentContest" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.isParentContest ? "true" : "false"}
                                />
                                {validation.touched.isParentContest && validation.errors.isParentContest ? (
                                    <div className="text-danger">{validation.errors.isParentContest}</div>
                                ) : null}
                            </div>


                            <div className="mb-3">
                                <Label htmlFor="date-field" className="form-label" > Data </Label>
                                <Flatpickr
                                name="date"
                                className="form-control"
                                id="date-field"
                                placeholder="Select date"
                                options={{
                                mode: "single",
                                    dateFormat: 'd M, Y',
                                }}
                                onChange={(date: any) => validation.setFieldValue("date", moment(date[0]).format("DD MMMM ,YYYY"))}
                                value={validation.values.date || ''}
                          />
                          {validation.errors.date && validation.touched.date ? (
                            <FormFeedback type="invalid" className='d-block'><>{validation.errors.date}</></FormFeedback>
                          ) : null}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <Button type="button" className="btn btn-light" onClick={toggleCreateCompetition} >
                            Inchide
                          </Button>
                          <Button type="submit" className="btn btn-success">
                            Adauga
                          </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    )   
}


export default CreateCompetition;
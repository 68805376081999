import { createAsyncThunk } from "@reduxjs/toolkit";
import {addRoleToUserError, addRoleToUserRequest, addRoleToUserSuccess, createUserError, createUserRequest, createUserSuccess, fetchUsersError, fetchUsersRequest, fetchUsersSuccess, removeRoleFromUserError, removeRoleFromUserRequest, removeRoleFromUserSuccess} from './reducer'

import {
    getUsersList as getUsersListApi,
    signupUser,
    updateUser as updateUserApi
} from "../../helpers/backend_helper"
import { toast } from "react-toastify";

export const getUsersList = () => async (dispatch: any) => {
    try {
      dispatch(fetchUsersRequest());
      const response: any = await getUsersListApi();
      dispatch(fetchUsersSuccess(response.users));  // Pass response directly
      return response;
    } catch (error: any) {
      dispatch(fetchUsersError(error.message));
    }
  };

export const updateUserRoles = (method: string, userId: string, data: any) => async (dispatch: any) => {
  switch(method) {
    case 'add':
      try {
        dispatch(addRoleToUserRequest());
        const response: any = await updateUserApi(userId, data);
        dispatch(addRoleToUserSuccess(response));
        toast.success("Rolul a fost adaugat cu succes", { autoClose: 3000 });
        return response;
      } catch (error: any) {
        dispatch(addRoleToUserError(error.message));
      }
      break;
    case 'remove':
      try {
        dispatch(removeRoleFromUserRequest());
        const response: any = await updateUserApi(userId, data);
        dispatch(removeRoleFromUserSuccess(response));
        toast.success("Rolul a fost eliminat cu succes", { autoClose: 3000 });
        return response;
      } catch (error: any) {
        dispatch(removeRoleFromUserError(error.message));
      }
      break;
  }
};

export const createUser = (data: any) => async (dispatch: any) => {
  try {
    dispatch(createUserRequest());
    console.log(data);
    const response: any = await signupUser(data);
    dispatch(createUserSuccess(response));
    dispatch(getUsersList());
    toast.success("Utilizatorul a fost creat cu succes", { autoClose: 3000 });
  } catch (error: any) {
    dispatch(createUserError(error.message));
  }
};
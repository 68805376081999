import { useEffect, useRef, useState } from "react";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { useDispatch } from "react-redux";
import firebase from "firebase/compat/app";
import { loginSuccess, logoutUserSuccess } from "slices/auth/login/reducer";
import { serializeFirebaseUser } from 'helpers/firebase_helper';
import { saveDeviceToken } from "helpers/backend_helper";
import { profileSuccess } from "slices/auth/profile/reducer";

const useProfile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [messagingToken, setTokenMessagingToken] = useState<string | null>(null);
  
  useEffect(() => {
    const firebaseAuth = getFirebaseBackend();

    // Firebase auth state listener
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const serializedUser = await serializeFirebaseUser(user);
        console.log('Fac dispatch loginSuccess din useProfile');
        setUserProfile(serializedUser);
        dispatch(profileSuccess(serializedUser));
        dispatch(loginSuccess(serializedUser));

        // Fetch messaging token
        const messagingToken = await firebaseAuth?.geMessagingToken();
        if (messagingToken) {
          if(localStorage.getItem("messagingToken") && localStorage.getItem("messagingToken") != messagingToken) {
            saveDeviceToken(messagingToken);
          }
          setTokenMessagingToken(messagingToken);
        }
      } else {
        dispatch(logoutUserSuccess(true));
      }
      setLoading(false);
    });
  }, [dispatch]);

  return { userProfile, loading, messagingToken };
};

export { useProfile };
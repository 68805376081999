import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Form, ModalBody, FormFeedback, Button, Table, Col, Container, Card, CardBody, CardHeader } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import SimpleBar from 'simplebar-react';
import { Link } from "react-router-dom";
import { addToCompetition as addToCompetitionApi} from "helpers/backend_helper"
import { createSelector } from "reselect";
import Select from 'react-select';
import Pagination from "Components/Common/Pagination";
import { addCompetitorToCompetition, removeUserFromCompetition } from "slices/thunks";
import TableContainer from "Components/Common/TableContainer";
import CompetitorDetails from "./CompetitorDetails";
import DeleteModal from "Components/Common/DeleteModal";



const ParticipantsTab = ({comp}: any) => {
    const [inviteType, setInviteType] = useState<'selectUser' | 'addCompetitor' | null>(null);

    const selectUserState = (state: any) => state.Users;
    const userListSelector = useMemo(() => createSelector(
        selectUserState,
        (userState) => ({
            userList: userState.userList,
            isLoadingUsers: userState.loading,
            usersError: userState.error,
        })
    ), [comp]);



    // const { userList, isLoadingUsers, usersError } = useSelector((state: any) => state.Users);
    const { userList, isLoadingUsers, usersError } = useSelector(userListSelector);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [competitorOffCanvas, setCompetitorOffCanvas] = useState<boolean>(false);
    const [selectedCompetitorId, setSelectedCompetitorId] = useState<any>('');

    const selectCompetitionState = (state: any) => state.Competitions;

    const competitionSelector = useMemo(() => createSelector(
        selectCompetitionState,
        (competitionState) => ({
            competitionsList: competitionState.competitions,
            competition: competitionState.competitions.find((c: any) => c._id === comp._id),
            isLoadingCompetition: competitionState.loading,
            competitionError: competitionState.error,
        })
    ), [comp]);

    const { competition , competitionsList} = useSelector(competitionSelector);
    // Create options for react-select
    const userOptions = useMemo(() => {
        console.log('Fac update la userOptions');
        return userList
        .filter((user: any) => user.roles.includes('competitor'))
        .filter((user: any) => 
            !competition.participants.some((participant: any) => participant._id === user._id)
        )
        .map((user: any) => ({
            value: user._id,
            label: `${user.name} ${user.surname}`,
        }));
    }, [userList, comp]);


    const [isUserSignUp, setIsUserSignUp] = useState<boolean>(false);
    const toggleIsUserSignUp = () => {
        setIsUserSignUp(!isUserSignUp);
        setInviteType(null);
    };
    const dispatch: any = useDispatch();

    const { loading, user } = useSelector((state: any) => state.Login);
    
    const selectDanceSchoolState = (state: any) => state.DanceSchool;

    const danceSchoolSelector = createSelector(
        selectDanceSchoolState,
        (danceSchoolState) => ({
            danceSchoolList: danceSchoolState.danceSchoolList,
            isLoadingDanceSchool: danceSchoolState.loading,
            danceSchoolError: danceSchoolState.error,
        })
    );

    const { danceSchoolList, isLoadingDanceSchool, danceSchoolError } = useSelector(danceSchoolSelector);
    // Create options for react-select
    const danceSchoolOptions = useMemo(() => {
        return danceSchoolList.map((school: any) => ({
            value: school._id,
            label: school.name,
        }));
    }, [danceSchoolList]);

    const competitionsTags = useMemo(() => {
        return competitionsList.map((competition: any) => ({
            value: competition.name,
            label: competition.name,
            id: competition._id,
        }));
    }, [competitionsList]);

    const onClickDelete = (participant: any) => {
        setSelectedCompetitorId(participant);   
        setDeleteModal(true);
    };

    const handleDelete = () => {
        dispatch(removeUserFromCompetition(competition._id, selectedCompetitorId._id));
        setDeleteModal(false);
    };

    const handleCompetitorOffCanvas = (participant: any) => {
        setSelectedCompetitorId(participant);
        setCompetitorOffCanvas(true);
    };

    const columnNames = useMemo(() => [
        {
            header: "Nume",
            accessorKey: "name",
            enableColumnFilter: false,
        },
        {
            header: "Prenume",
            accessorKey: "surname",
            enableColumnFilter: false,

        },
        {
            header: "Telefon",
            accessorKey: "phoneNumber",
            enableColumnFilter: false,
        },
        {
            header: "Actions",
            accessorKey: "_id",
            enableColumnFilter: false,
            cell: (cellProps: any) => {
                return (
                    <ul className="list-inline hstack gap-2 mb-0">
                        <li className="list-inline-item">
                            <Link
                            to="#"
                            className="text-primary d-inline-block"
                            onClick={(e) => {
                                e.preventDefault();
                                handleCompetitorOffCanvas(userList.find((user: any) => user._id === cellProps.getValue()));
                            }}
                            >
                                <i className="ri-eye-fill fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item" title="Delete">
                            <Button color="danger" className="btn-sm" 
                            onClick={(e) => {
                                e.preventDefault();
                                onClickDelete(userList.find((user: any) => user._id === cellProps.getValue()))
                            }}>
                                <i className="ri-delete-bin-line"></i>
                            </Button>
                        </li>
                    </ul>
                )
            }
        }
    ], []);


    const selectUserFormik = useFormik({
        initialValues: {
            participantId: null as string | null,
            competition: {
                id: comp._id,
                name: comp.name,
            },
        },
        validationSchema: Yup.object().shape({
            participantId: Yup.string().required("Este necesar să selectați un utilizator."),
        }),
        onSubmit: (values) => {
            dispatch(addCompetitorToCompetition(values));
            selectUserFormik.resetForm();
            toggleIsUserSignUp();
            setCurrentParticipantPage(1);
            setInviteType(null);
        }
    });

    useEffect(() => {
        setIsUserSignUp(false);
        selectUserFormik.setValues({
            participantId: null,
            competition: {
                id: comp._id,
                name: comp.name,
            },
        });
    }, [comp]);

    const validation = useFormik({
        initialValues: {
            name: "",
            surname: "",
            email: "",
            dateOfBirth: null,
            phoneNumber: "",
            danceSchool: null,
            competition: {
                id: competition._id,
                name: competition.name,
            },
        },
        validationSchema: Yup.object().shape({  
            name: Yup.string().required("Prenumele este obligatoriu"),
            surname: Yup.string().required("Numele de familie este obligatoriu"),
            email: Yup.string().email("Email-ul este invalid"),
            // dateOfBirth: Yup.date().optional(),
            phoneNumber: Yup.string().min(10, "Numarul de telefon trebuie sa aiba minim 10 caractere").required("Numarul de telefon este obligatoriu"),
            danceSchool: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            dispatch(addCompetitorToCompetition(values));
            validation.resetForm();
            toggleIsUserSignUp();
            setCurrentParticipantPage(1);
            setInviteType(null);
        }
    });

    // Participant Search State
    const [participantSearchTerm, setParticipantSearchTerm] = useState<string>("");

    // Handler for participant search input
    const handleParticipantSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setParticipantSearchTerm(e.target.value);
        },
        []
    );

    // Filtered participants based on search term
    const filteredParticipants = useMemo(() => {
        if (!participantSearchTerm) return competition.participants;
        return competition.participants.filter((participant:any) =>
            Object.values(participant).some(
                (field) =>
                    typeof field === "string" &&
                    field.toLowerCase().includes(participantSearchTerm.toLowerCase())
            )
        );
    }, [competition.participants, participantSearchTerm]);


    // Pagination for Participants
    const PARTICIPANTS_PER_PAGE = 10;
    const [currentParticipantPage, setCurrentParticipantPage] = useState<number>(1);

    const indexOfLastParticipant = currentParticipantPage * PARTICIPANTS_PER_PAGE;
    const indexOfFirstParticipant = indexOfLastParticipant - PARTICIPANTS_PER_PAGE;

    const currentParticipants = useMemo(() => {
        return filteredParticipants.slice(indexOfFirstParticipant, indexOfLastParticipant);
    }, [filteredParticipants, indexOfFirstParticipant, indexOfLastParticipant]);


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDelete}
                onCloseClick={() => setDeleteModal(false)}
                title="Sterge Participant"
                body={`Esti sigur ca vrei sa stergi participantul ${selectedCompetitorId.name} ${selectedCompetitorId.surname} din competitia ${competition.name}?`}
            />
            <Row>
                <Col lg={12}>
                    <Card id="participants-card">
                        <CardHeader className="border-0">
                            <Row className="g-4 align-items-center">
                                <Col sm={3}>
                                    <div className="col-sm">
                                        <div className="d-flex">
                                            <div className="search-box me-2">
                                                <Input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="search-bar-1"
                                                    placeholder="Search member..." 
                                                    value={participantSearchTerm}
                                                    onChange={(e) => handleParticipantSearch(e)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <div className="col-sm-auto ms-auto">
                                    <div className="col-sm-auto">
                                        <div>
                                            {user && (user.roles.includes('admin') || user.roles.includes('superadmin')) && !competition.isParentContest && (
                                                <Button 
                                                type="button" 
                                                color="danger" 
                                                onClick={toggleIsUserSignUp}
                                                disabled={competition.isLive}
                                                >
                                                    <i className="ri-share-line me-1 align-bottom"></i> Invite Member
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody className="pt-3">
                            <div>
                                {filteredParticipants.length === 0 ? (
                                    <div className="text-center page-content">
                                                                        <div className="row">
                                        <Row><h5>Lista Participanților</h5></Row>
                                        </div>
                                        <p>Nu există participanți în această competiție.</p>
                                    </div>
                                    ) : (
                                    
                                    <div className= "border-0 pt-3 page-content">
                                        
                                        <TableContainer 
                                            columns={columnNames}
                                            data={filteredParticipants || []}
                                            customPageSize={PARTICIPANTS_PER_PAGE}
                                            tableClass="align-middle table-nowrap"
                                            theadClass="table-light text-muted text-uppercase"
                                            divClass="table-responsive table-card"

                                            />
                                </div>)}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Container fluid>
                <Row className="g-4 mb-3">


                </Row>
                <Offcanvas 
                    isOpen={isUserSignUp} 
                    toggle={toggleIsUserSignUp} 
                    className="invite-member-modal"
                    scrollable={true}
                    backdrop={false}
                    direction="end" 
                    style={{ minWidth: "70vh" }}
                >
                    <OffcanvasHeader toggle={toggleIsUserSignUp} id="invite-member-modal-header" className="bg-primary-subtle p-3">
                        {inviteType === null ? "Invită Membru" : (inviteType === 'selectUser' ? "Selectează Utilizator" : "Adaugă Competitor")}
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        {/* Initial Choice View */}
                        {inviteType === null && (
                            <div className="d-flex flex-column align-items-center">
                                <Button color="primary" className="mb-3 w-75" onClick={() => setInviteType('selectUser')}>
                                    <i className="ri-user-line me-2"></i>Alege un competitor existent
                                </Button>
                                <Button color="success" className="w-75" onClick={() => setInviteType('addCompetitor')}>
                                    <i className="ri-add-line me-2"></i>Adaugă un competitor nou
                                </Button>
                            </div>
                            
                        )}
                        {/* Select Existing User View */}
                        {inviteType === 'selectUser' && (
                            <Form 
                            onSubmit={(e) => {
                                e.preventDefault();
                                selectUserFormik.handleSubmit();
                                    return false;
                                }}
                            >
                                <FormGroup>
                                    <Label htmlFor="participantId">Selectați Utilizatorul</Label>
                                    <Select
                                        id="participantId"
                                        name="participantId"
                                        options={userOptions}
                                        value={userOptions.find((option: any) => option.value === selectUserFormik.values.participantId)}
                                        onChange={(selectedOption: any) => {
                                            selectUserFormik.setFieldValue("participantId", selectedOption ? selectedOption.value : null);
                                        }}
                                        isClearable
                                        isLoading={isLoadingUsers}
                                        placeholder="Selectați un utilizator..."
                                    />
                                    {selectUserFormik.touched.participantId && selectUserFormik.errors.participantId && (
                                        <div className="text-danger mt-1">
                                            {selectUserFormik.errors.participantId}
                                        </div>
                                    )}
                                    <div className="mb-3">
                                        <Label htmlFor="competitionName" className="form-label"> Competitia la care participa </Label>
                                        <Input type="text" className="form-control" id="disabledInput" defaultValue={competition.name} disabled />
                                    </div>
                                </FormGroup>
                                <div className="d-flex justify-content-end">
                                    <Button 
                                    type="button" 
                                    color="secondary" 
                                    className="me-2" 
                                    onClick={() => setInviteType(null)}>
                                        Inapoi
                                    </Button>
                                    <Button type="submit" className="btn btn-success" disabled={selectUserFormik.isSubmitting}>
                                        {selectUserFormik.isSubmitting ? "Adăugare..." : "Adaugă"}
                                    </Button>
                                </div>
                            </Form>
                        )}

                        {/* Add New Competitor View */}
                        {inviteType === 'addCompetitor' && (   
                        <Form 
                            className="tablelist-form"
                            onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                                return false;
                            }}
                            >
                            <Input type="hidden" id="id-field" />
                            <div className="mb-3">
                                <Label htmlFor="name" className="form-label"> Prenumele </Label>
                                <Input type="text" className="form-control" id="nume" placeholder="Prenumele competitorului" 
                                name="name" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.name}
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <div className="text-danger">{validation.errors.name}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="name" className="form-label"> Numele </Label>
                                <Input type="text" className="form-control" id="surname" placeholder="Numele de familie al competitorului" 
                                name="surname" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.surname}
                                />
                                {validation.touched.surname && validation.errors.surname ? (
                                    <div className="text-danger">{validation.errors.surname}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="email" className="form-label"> Email </Label>
                                <Input type="text" className="form-control" id="email" placeholder="Email-ul competitorului" 
                                name="email" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.email}
                                />
                                {validation.touched.email && validation.errors.email ? (
                                    <div className="text-danger">{validation.errors.email}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="date-field" className="form-label" > Data nasterii </Label>
                                <Flatpickr
                                name="date"
                                className="form-control"
                                id="date-field"
                                placeholder="Select date"
                                options={{
                                mode: "single",
                                    dateFormat: 'd M, Y',
                                }}
                                onChange={(date: any) => validation.setFieldValue("dateOfBirth", moment(date[0]).format("DD MMMM ,YYYY"))}
                                value={validation.values.dateOfBirth || ''}
                                />
                                {validation.errors.dateOfBirth && validation.touched.dateOfBirth ? (
                                    <FormFeedback type="invalid" className='d-block'><>{validation.errors.dateOfBirth}</></FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="competitionName" className="form-label"> Competitia la care participa </Label>
                                <Input type="text" className="form-control" id="disabledInput" defaultValue={competition.name} disabled />
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="phoneNumber" className="form-label"> Numarul de telefon </Label>
                                <Input type="text" className="form-control" id="phoneNumber" placeholder="Numarul de telefon al competitorului" 
                                name="phoneNumber" onChange={validation.handleChange} 
                                onBlur={validation.handleBlur} 
                                value={validation.values.phoneNumber}
                                />
                                {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                    <div className="text-danger">{validation.errors.phoneNumber}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label htmlFor="danceSchool" className="form-label"> Scoala de dans </Label>
                                <Select
                                    id="danceSchool"
                                    name="danceSchool"
                                    options={danceSchoolOptions}
                                    value={danceSchoolOptions.find((option: { value: null; }) => option.value === validation.values.danceSchool)}
                                    onChange={(selectedOption: any) => {
                                        validation.setFieldValue("danceSchool", selectedOption ? selectedOption.value : null);
                                    }}
                                    isClearable
                                    placeholder="Selectează o școală de dans (opțional)"
                                />    
                                {validation.touched.danceSchool && validation.errors.danceSchool && (
                                    <div className="text-danger mt-1">
                                        {validation.errors.danceSchool}
                                    </div>
                                )}                   
                            </div>
                            <div className="hstack gap-2 justify-content-end">
                                <Button type="button" className="btn btn-light" onClick={toggleIsUserSignUp} >
                                    Inchide
                                </Button>
                                <Button type="submit" className="btn btn-success" disabled={validation.isSubmitting}>
                                    Adauga
                                </Button>
                                </div>
                            </Form>
                        )}
                    </OffcanvasBody>
                </Offcanvas>
                {/* Participants Table */}
                <Row className="mt-4" >
                    <Col lg={12}>
           
                    </Col>
                </Row>
            </Container>
            <CompetitorDetails 
                competitorId={selectedCompetitorId}
                isOpen={competitorOffCanvas}
                toggle={() => setCompetitorOffCanvas(!competitorOffCanvas)}
            />
        </React.Fragment>
    );
}

export default ParticipantsTab; 
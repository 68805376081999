import BreadCrumb from "Components/Common/BreadCrumb";
import RoleBadges from "Components/Common/RoleBadges";
import RoleSelector from "Components/Common/RoleSelector";
import TableContainer from "Components/Common/TableContainer";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader, Col, Container, Input, Row, Table } from "reactstrap";
import { createSelector } from "reselect";
import { Role } from "types";
import CompetitorDetails from "../Dashboard/CompetitorDetails";


// Define available roles
const availableRoles: Role[] = [
  "admin",
  "superadmin",
  "sales",
  "competitor",
  "owner",
  "judge",
  "user",
  "coach",
];

// Generate role options for Select components
const availableRolesOptions = availableRoles.map((role) => ({
  value: role,
  label: role.charAt(0).toUpperCase() + role.slice(1),
}));


const CompetitionUserList = () => {
    document.title = "Competition | Users";

    const dispatch: any = useDispatch();
    const { competitionId } = useParams();

    // State for search term
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Pagination state
    const [currentPage, setCurrentPage] = useState<number>(1);
    const usersPerPage = 20; // Adjust as needed



    const competitionSelector = useMemo(() => createSelector(
        (state: any) => state.Competitions,
        (competitionState) => ({
            competitionsList: competitionState.competitions
        })
    ), [competitionId]);

    const usersSelector = useMemo(() => createSelector(
        (state: any) => state.Users,
        (userState) => ({
            userList: userState.userList
        })
    ), [competitionId]);

    const { competitionsList } = useSelector(competitionSelector);
    const { userList } = useSelector(usersSelector);
    console.log('CompetitionID ',competitionId);

    const selectedCompetition = useMemo(() => {
      return competitionsList.find((comp: any) => comp._id === competitionId);
    }, [competitionsList, competitionId]);
    
    const childCompetitions = useMemo(() => {
      if (selectedCompetition && selectedCompetition.isParentContest) {
        return competitionsList.filter(
          (comp: any) => comp.parentContest === selectedCompetition._id
        );
      }
      return [];
    }, [selectedCompetition, competitionsList]);

    const relevantCompetitionIds = useMemo(() => {
      if (!selectedCompetition) return [];
      const ids = [selectedCompetition._id];
      if (selectedCompetition.isParentContest) {
        childCompetitions.forEach((child: any) => ids.push(child._id));
      }
      return ids;
    }, [selectedCompetition, childCompetitions]);
    
    const filteredUsers = useMemo(() => {
      return userList.filter((user: any) =>
        user.competitions.some((compId: string) =>
          relevantCompetitionIds.includes(compId)
        )
      );
    }, [userList, relevantCompetitionIds]);

    const usersWithCompetitions = useMemo(() => {
      return filteredUsers.map((user: any) => {
        const userCompetitions = user.competitions.filter((compId: string) =>
          relevantCompetitionIds.includes(compId)
        );
        // Map competition IDs to competition names
        const competitionNames = userCompetitions.map(
          (compId: string) => competitionsList.find((comp: any) => comp._id === compId)?.name
        );
        return {
          ...user,
          competitionNames: competitionNames.filter(Boolean), // Remove undefined
        };
      });
    }, [filteredUsers, relevantCompetitionIds, competitionsList]);
    console.log('Child competitions: ', childCompetitions);
    console.log('Filtered users: ', filteredUsers);
    console.log('Users with competitions: ', usersWithCompetitions);

    const searchedUsers = useMemo(() => {
      if (!searchTerm.trim()) return usersWithCompetitions;
      const lowerSearch = searchTerm.toLowerCase();
      return usersWithCompetitions.filter(
        (user: any) =>
          user.name.toLowerCase().includes(lowerSearch) ||
          user.surname.toLowerCase().includes(lowerSearch)
      );
    }, [usersWithCompetitions, searchTerm]);



    const columns =  useMemo(() => [
      {
        header: 'Name',
        id: 'fullName',
        accessorFn: (row: { name: any; surname: any; }) => `${row.name} ${row.surname}`,
        enableColumnFilter: false,
      },
      {
        header: 'Telefon',
        accessorKey: 'phoneNumber',
        enableColumnFilter: false,
      },  
      {
        header: "Competitions",
        accessorKey: "competitionNames",
        enableColumnFilter: false,
        cell: ({ row }: any) => (
          <div>
            {row.original.competitionNames.map((compName: string, index: number) => (
              <Badge color="primary" className="me-1" key={index}>
                {compName}
              </Badge>
            ))}
          </div>
        ),
      },
      {
        header: "Actions",
        accessorKey: "_id",
        enableColumnFilter: false,
        cell: ({ row }: any) => (
          <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                  <Link
                  to="#"
                  className="text-primary d-inline-block"
                  onClick={(e) => {
                      e.preventDefault();
                      handleCompetitorOffCanvas(userList.find((user: any) => user._id === row.original._id));
                  }}
                  >
                      <i className="ri-eye-fill fs-16"></i>
                  </Link>
              </li>
            </ul>
          )
        }
    ],[userList]);

    const [competitorOffCanvas, setCompetitorOffCanvas] = useState<boolean>(false);
    const [selectedCompetitorId, setSelectedCompetitorId] = useState<any>('');

    const handleCompetitorOffCanvas = (participant: any) => {
      console.log('Participant: ', participant);
      setSelectedCompetitorId(participant);
      setCompetitorOffCanvas(true);
    };


    return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* <BreadCrumb title="Users" pageTitle="Competition" /> */}
              <Row>
                <Card id="participants-card">
                  <CardHeader className="border-0">
                    <div className="d-md-flex align-items-center">
                      <h5 className="card-title mb-3 mb-md-0 flex-grow-1">
                        Lista Competitorilor din <b>{selectedCompetition?.name}</b>
                      </h5>
                    </div>
                      <Row className="g-4 align-items-center">
                        <Col sm={3}>
                            <div className="col-sm">
                                <div className="d-flex">
                                    <div className="search-box me-2">
                                        <Input 
                                            type="text" 
                                            className="form-control" 
                                            id="search-bar-1"
                                            placeholder="Search member..." 
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        </Row>
                  </CardHeader>
                  <CardBody className="pt-3">
                    <TableContainer
                      customPageSize={usersPerPage}
                      columns={columns}
                      data={searchedUsers}
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      divClass="table-responsive table-card"
                    />
                  </CardBody>
                </Card>
              </Row>
            </Container>
            <CompetitorDetails 
                competitorId={selectedCompetitorId}
                isOpen={competitorOffCanvas}
                toggle={() => setCompetitorOffCanvas(!competitorOffCanvas)}
            />
          </div>
        </React.Fragment>
      );
};

export default CompetitionUserList;
import TableContainer from "Components/Common/TableContainer";
import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import { createSelector } from "reselect";
import { DanceType } from "types";



interface SongListProps {
    competition: any;
}

const SongList: React.FC<SongListProps> = ({ competition }) => {

    const dispatch: any = useDispatch();

    const selectSongsState = (state: any) => state.Songs;

    const songsSelector = createSelector(
        selectSongsState,
        (songsState: any) => ({
            songs: songsState.songs,
            isLoadingSongs: songsState.loading,
            songsError: songsState.error,
        })
    );

    const { songs, isLoadingSongs, songsError } = useSelector(songsSelector);



    const selectCompetitionState = (state: any) => state.Competitions;
    const competitionsSelector = createSelector(
        selectCompetitionState,
        (competitionsState: any) => ({
            competitions: competitionsState.competitions,
            isLoadingCompetitions: competitionsState.loading,
            competitionsError: competitionsState.error,
        })
    );

    const { competitions } = useSelector(competitionsSelector);

    const [searchQuery, setSearchQuery] = useState<string>('');

    const columnNames = useMemo(() => [
        {
            header: "Nume",
            accessorKey: "name",
            enableColumnFilter: false,
        },
        {
            header: "Song Type",
            accessorKey: "type",
            enableColumnFilter: false,

        },
        {
            header: "Competitions",
            accessorKey: "competitions",
            enableColumnFilter: false,
        }
    ], []);


    // Handler for search input change
    const handleSongSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.target.value);
        },
        []
    );

    // Filtered songs based on search query
    const filteredSongs = useMemo(() => {
        if (!searchQuery) return songs;
        return songs.filter((song: any) =>
            song.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, songs]);

    // Pagination for Participants
    const SONGS_PER_PAGE = 15;
    const [currentSongPage, setCurrentSongPage] = useState<number>(1);

    const indexOfLastSong = currentSongPage * SONGS_PER_PAGE;
    const indexOfFirstSong = indexOfLastSong - SONGS_PER_PAGE;

    const currentSongs = useMemo(() => {
        return filteredSongs.slice(indexOfFirstSong, indexOfLastSong);
    }, [filteredSongs, indexOfFirstSong, indexOfLastSong]);

    // Map of songId to competitions
    const songIdToCompetitionsMap = useMemo(() => {
        const map: { [key: string]: string[] } = {};
        competitions.forEach((comp: any) => {
            comp.availableSongs.forEach((song: any) => {
                if (map[song._id]) {
                    map[song._id].push(comp.name);
                } else {
                    map[song._id] = [comp.name];
                }
            });
        });
        return map;
    }, [competitions]);


    // Handler to update dance type
    const handleDanceTypeChange = (songId: string, newType: DanceType) => {
        // dispatch(updateDanceType({ songId, newType }))
            // .unwrap()
            // .then(() => {
            //     toast.success('Dance type updated successfully!');
            // })
            // .catch((err: any) => {
            //     toast.error(err || 'Failed to update dance type.');
            // });
    };

    // Handler to assign competitions
    const handleCompetitionChange = (songId: string, selectedOptions: any[]) => {
        const competitionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        // dispatch(assignCompetitions({ songId, competitionIds }))
            // .unwrap()
            // .then(() => {
            //     toast.success('Competitions updated successfully!');
            // })
            // .catch((err: any) => {
            //     toast.error(err || 'Failed to update competitions.');
            // });
    };

    
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card id="participants-card">
                        <CardHeader className="border-0">
                            <Row className="g-4 align-items-center">
                                <Col sm={3}>
                                    <div className="col-sm">
                                        <div className="d-flex">
                                            <div className="search-box me-2">
                                                <Input 
                                                    type="text" 
                                                    className="form-control" 
                                                    id="search-bar-1"
                                                    placeholder="Search song..." 
                                                    value={searchQuery}
                                                    onChange={(e) => handleSongSearch(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="pt-3">
                            {filteredSongs.length === 0 ? (
                                <div className="text-center page-content">
                                    <div className="row">
                                        <Row><h5>Lista Melodiilor</h5></Row>
                                    </div>
                                    <p>Nu există melodii în această competiție.</p>
                                </div>
                            ) : (
                            <div>
                                <TableContainer 
                                    columns={columnNames}
                                    data={filteredSongs || []}
                                    customPageSize={SONGS_PER_PAGE}
                                    divClass="table-responsive table-card"
                                    tableClass="align-middle"
                                />
                            </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );







    // return (
    //     <div>
    //                     <FormGroup>
    //             <Label for="search">Search Songs</Label>
    //             <input
    //                 type="text"
    //                 id="search"
    //                 name="search"
    //                 className="form-control"
    //                 placeholder="Enter song name..."
    //                 value={searchQuery}
    //                 onChange={handleSearchChange}
    //             />
    //         </FormGroup>
    //         <Table bordered responsive>
    //             <thead>
    //                 <tr>
    //                     <th>#</th>
    //                     <th>Name</th>
    //                     <th>Dance Type</th>
    //                     <th>Competitions</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {songs.map((song: any, index: number) => (
    //                     <tr key={song._id}>
    //                         <th scope="row">{index + 1}</th>
    //                         <td>{song.name}</td>
    //                         <td>{song.type}</td>
    //                         <td>
    //                         {songIdToCompetitionsMap[song._id] && songIdToCompetitionsMap[song._id].length > 0
    //                             ? songIdToCompetitionsMap[song._id].join(', ')
    //                             : 'None'}
    //                         </td>
    //                     </tr>
    //                 ))}
    //             </tbody>
    //         </Table>
    //     </div>
    // );
}

export default SongList;
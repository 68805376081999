import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Container } from "reactstrap";

const userSelector = createSelector(
    (state: any) => state.Profile,
    (profileState) => ({
        user: profileState.user
    })
);

const SalsaNamaLive = () => {
    const { user } = useSelector(userSelector);
    console.log(user);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Live" pageTitle="SalsaNama" />
                    {/* Use user data here */}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SalsaNamaLive;
import React from "react";


const BachataNamaLive = () => {
    document.title = "SalsaNama | Live";
    return(
        <React.Fragment>
            
        </React.Fragment>
    ); 
}


export default BachataNamaLive;
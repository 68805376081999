// Include both helper files with needed methods
import { getCompetitions as getCompetitionsApi } from 'helpers/backend_helper';
import { createCompetition as createCompetitionApi } from 'helpers/backend_helper';
import { fetchCompetitionsRequest, fetchCompetitionsSuccess, fetchCompetitionsFailure, createCompetitionFailure, createCompetitionRequest, createCompetitionSuccess, addToCompetitionRequest, addToCompetitionSuccess, addToCompetitionFailure, removeUserFromCompetitionSuccess, removeUserFromCompetitionFailure, removeUserFromCompetitionRequest } from './reducer';
import { toast } from 'react-toastify';
import { addToCompetition as addToCompetitionApi} from "helpers/backend_helper"
import { removeFromCompetition as removeFromCompetitionApi } from "helpers/backend_helper";
import { getUsersList } from 'slices/thunks';



// Thunk to fetch competitions
export const fetchCompetitions = () => async (dispatch: any) => {
  try {
    dispatch(fetchCompetitionsRequest());

    const response:any = await getCompetitionsApi();

    const competitions = response.competitions;

    if (Array.isArray(competitions)) {
      dispatch(fetchCompetitionsSuccess(competitions));
      dispatch(getUsersList());
    } else {
      throw new Error("Failed to fetch competitions");
    }
  } catch (error: any) {
    // console.log('Error in fetching competitions', error);
    dispatch(fetchCompetitionsFailure(error));
  }
};

// Thunk for other actions (similar to login/logout in the example)

// Example: Resetting the competition-related error flag
export const resetCompetitionErrorFlag = () => async (dispatch: any) => {
  try {
    // Assuming there's a resetErrorFlag action
    // dispatch(reset_login_flag());
  } catch (error: any) {
    dispatch(fetchCompetitionsFailure(error.message));
  }
};


export const createCompetition = (competition: any) => async (dispatch: any) => {
  try {
    dispatch(createCompetitionRequest());

    const response: any = await createCompetitionApi(competition);

    dispatch(createCompetitionSuccess(response));
    toast.success("Competition created successfully", { autoClose: 3000 });

  } catch (error: any) {
    dispatch(createCompetitionFailure(error));
    toast.error("Failed to create competition", { autoClose: 3000 });
  }
};


export const addCompetitorToCompetition = (competition: any) => async (dispatch: any) => {
  try {
    dispatch(addToCompetitionRequest());
    console.log(competition);
    const response: any = await addToCompetitionApi(competition);

    dispatch(addToCompetitionSuccess(response));
    dispatch(getUsersList());
  } catch (error: any) {
    dispatch(addToCompetitionFailure(error));
    toast.error("Failed to competitor add to competition", { autoClose: 3000 });
  }
};

export const removeUserFromCompetition = (competitionId: string, userId: string) => async (dispatch: any) => {
  try {
    dispatch(removeUserFromCompetitionRequest());
    const response: any = await removeFromCompetitionApi(competitionId, userId);
    dispatch(removeUserFromCompetitionSuccess(response));
    toast.success("User removed from competition", { autoClose: 3000 });
  } catch (error: any) {
    dispatch(removeUserFromCompetitionFailure(error));
    toast.error("Failed to remove user from competition", { autoClose: 3000 });
  }
};
